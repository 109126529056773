const f = (a, f) => {
    if (f === undefined) return a;
    return a[f];
}

export const sumAmounts = (amounts, property) => { 
    let amountValue = 0;
    let currencyCode = "EUR";
    if (amounts !== null && amounts.length > 0) {
        amountValue = amounts.reduce((a, b) => a + (f(b,property).amountValue || 0), 0);
        currencyCode =  f(amounts[0],property).currencyCode;
    }
    return { amountValue, currencyCode };
  }