import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import PageComponent from '../components/page'
import { UpdateValue, YearChange } from '../../store/reducers/controlsStore'
import { Alert } from 'adp-react-components'

class InputsView extends React.Component {
  constructor(props) {
    super(props)
    this.state = { actifIndex: 0 }
  }

  onOpenCollapse = (pageIndex, open) => {
    if (open)
      this.setState({ actifIndex: pageIndex })
  }

  onChange = (pageIndex, id, value) => {
    this.props.UpdateValue(pageIndex, id, value)
    if (id === '#year') this.props.YearChange(value)
    this.setState({ pageIndex, id, value })
  }

  onBlur = (pageIndex, id, value) => {
    const { UpdateValue } = this.props
    UpdateValue(pageIndex, id, value)
  }

  calculate = (delay) => {
    const { onCalculate } = this.props
    setTimeout(() => {
      onCalculate()
    }, delay)
  }

  render() {
    const { pages = [], remark } = this.props
    const { actifIndex = 0 } = this.state

    return (<div className="prepay-input-pages">
      {remark ? (<Alert closeable={false} type="warning">{remark}</Alert>) : undefined}
      {pages.map((page) => (
        <PageComponent
          key={page.key}
          id={page.key}
          pageIndex={page.pageIndex}
          title={page.title}
          components={page.components}
          inError={page.inError}
          actifIndex={actifIndex}
          onOpenCollapse={this.onOpenCollapse}
          onChangeHandler={this.onChange}
          onBlurHandler={this.onBlur}
        />))}
    </div>)
  }
}

InputsView.propTypes = {
  onCalculate: PropTypes.func.isRequired
}

function mapStateToProps(state, ownProps) {
  return {
    ...ownProps,
    ...state.controlsStore
  }
}


export default connect(mapStateToProps, { UpdateValue, YearChange })(InputsView)