import React from 'react';
import PropTypes from 'prop-types';
import Label from './Label';
import { isNullOrEmptyOrWhitspace } from '../tool/tools'

const FormInput =   ({ prefix = "", suffix = "", className = "", required, error = "", label = "", children, help }) => {
  const errorClassName = isNullOrEmptyOrWhitspace(error) ? error : "has-error dirty touched";
  return (
    <div className={`vdl-form-group ${errorClassName} ${className}`}>
      <Label required={required} error={error} labelId={label} />
      <div className='input-container'>
        <span className='input-prefix'>{prefix}&nbsp;</span>
        <div className='input-element'>
          {children}
        </div>
        <span className='input-suffix'>&nbsp;{suffix}</span>
      </div>
      { help ? help : undefined }
    </div>
  );
};

FormInput.propTypes = {
  required: PropTypes.bool,
  className: PropTypes.string,
  error: PropTypes.any,
  label: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.element
  ]).isRequired,
};

export default FormInput;