import output  from '../defaults/output'
import { inputErrors, AnyPayment }  from '../defaults/componentState'
import { getCaoMessage } from '../defaults/caoRemarks'

import { calcuateRequestType,
         calcuateRequestSucessType,
         calcuateRequestFailureType,
         createPdfRequestType,
         calcuateValidationFailureType,
         createPdfSuccessType } from '../actions/calculationActions'


const defaultState = {
    busy: true,
    errors: [],
    assignment: output.assignment,
    payStatements: output.payStatements,
    assignmentCosts: output.assignmentCosts
}

const calculationState = (() => {
  let _count = 0
  let _requestId = null
  const f = (c) => _count += c
  const validate = (id) => {
      if (id === undefined) return true
      const d = new Date(id)
      const isvalid = _requestId === null || d > _requestId
      _requestId = d
      return isvalid
  }

  return {
    add: () => f(1),
    remove: () => _count > 0 ? f(-1) : f(0),
    busy: () => _count !== 0,
    validateResponseId: validate
  }
})()

export const initializeStatement = () => {
  return {
    type: calcuateRequestSucessType,
    response: output
  }
}

export const calculate = (input, showErrors) => {
  const fsYear = input[0].components[0].value;
  const endpoint = `/api/prepay/calculate?fiscalYear=${fsYear}`

  const errors = inputErrors(input, showErrors)
  if (errors.length) {
    return {
      type: calcuateValidationFailureType,
      response: { input, errors, showErrors, message:'GENERIC_VALIDATION_ERROR' }
    }
  }

  calculationState.add()
  return {
    api: {
      type: calcuateRequestType,
      resource: {
        endpoint,
        method: 'POST',
        body: JSON.stringify(input)
      }
    }
  }
}

const setDownloadTimeout = (fileId) => {
  setTimeout(() => {
    const ref = `/api/Pdf/download/${fileId}`
    const endpointUri = (process.env.NODE_ENV === 'development'
    ? 'http://localhost:8602' : '') + ref;

    window.location.href = endpointUri
  }, 100)
}


export const createPdf = (input, type) => {
  const endpoint = `/api/Pdf/createpdf?pdfType=${type}`
  const year = input.payStatements[0].payPeriod.startDate.substring(0,4)
  const cao = input.assignment.organization.additionalProperties.cao.substring(0,4)
  input.assignment.organization.additionalProperties.caoMessage = getCaoMessage(cao, year)

  return {
    api: {
      type: createPdfRequestType,
      resource: {
        endpoint,
        method: 'POST',
        body: JSON.stringify(input)
      }
    }
  }
}

export const isCalculateReady = (pages) => AnyPayment(pages)


export const reducer = (state = defaultState, action) => {

  switch (action.type)  {

    case calcuateRequestType:
      return { ...state,
               busy: true,
               validationMessage: undefined }

      case calcuateValidationFailureType:
        return { ...state, validationMessage: action.response.message }

    case calcuateRequestSucessType:
      calculationState.remove()
      const { responseId } = action.response
      return calculationState.validateResponseId(responseId)
        ? { ...state,
            busy: calculationState.busy(),
            ...action.response,
            error: undefined,
            validationMessage: undefined }
        : { ...state,
            busy: calculationState.busy(),
            validationMessage: undefined }


    case calcuateRequestFailureType:
      calculationState.remove()
      const { error }  = action.response
      return { ...state,
               busy: calculationState.busy(),
               ...output,
               error,
               validationMessage: undefined }

    case createPdfSuccessType:
        setDownloadTimeout(action.response.fileId)
      return state


    default:
      return state
  }
}
