import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTranslator } from '../localize/LocalizationProvider';
import FaInfoCircle from 'adp-react-icons/lib/fa/info-circle';
import  { Alert } from 'adp-react-components';


const translate = (translator, resource, year) => {
    const help = translator.translate(resource)

    return help[year] || help['default'] || help

}

class HelpComponent extends React.Component {
    constructor(props){
      super(props);
      this.state = { isOpen: false };
    }

    onClick = () => {
        const { isOpen = false } = this.state;
        this.setState({ isOpen: !isOpen });
    }



    render = () =>
    {
        const { translator, id, content, fiscalYear } = this.props;
        const langContent = translate(translator, id, fiscalYear)
        const { title, text } = langContent

        const { isOpen } = this.state;

        const strings = content !== undefined ? content : text;
        const htmlString = (Array.isArray(strings)) ? text.join("") : strings;

        return (<React.Fragment>
        {langContent || content
            ? <React.Fragment>
                    <FaInfoCircle className="help-icon" onClick={this.onClick} />
                    <div className={`help-text help-text--${ isOpen ? "open" : "closed" }`}>
                        <Alert
                            type="info"
                            title={title}
                            size="sm"
                            closable="true" >
                                <div dangerouslySetInnerHTML={{ __html: htmlString}}></div>
                             </Alert>
                    </div>
                </React.Fragment>
            : undefined }
            </React.Fragment>);
    }
}

function mapStateToProps(state, ownProps) {
    const { controlsStore: { fiscalYear } } = state

    return {
      ...ownProps,
      fiscalYear
    }
  }

HelpComponent.propTypes = {
    id: PropTypes.string.isRequired,
    translator: PropTypes.object.isRequired,
    text: PropTypes.string
}
export default connect(mapStateToProps)(withTranslator(HelpComponent, "HelpText"));
