import 'isomorphic-fetch';
import { getCulture } from '../localize/culture';

const defaultInit = {
  credentials: 'include'
};

const defaultHeaders = {
  'pragma': 'no-cache',
  'cache-control': 'no-cache',
  'content-type': 'application/json',
};

export const callApi = ({ endpoint, ...other }) => {
  const endpointUri = (process.env.NODE_ENV === 'development'
  ? 'http://localhost:8602' : '') + endpoint;
  return fetch(endpointUri,
    {
      ...defaultInit,
      ...other,
      headers: {
        'Accept-Language': getCulture(),
        'prepay-csrfH': sessionStorage.getItem('prepay-csrfH'),
        ...defaultHeaders
      }
    }).then(response => {
      const contentType = response.headers.get("content-type");

      if (contentType === null) {
        return handleDefault(response);
      }

      if (contentType.includes('application/json')) {
        return handleJson(response);
      }

      return handleDefault(response);
    }, error => Promise.reject({ error }));
};

const handleJson = response => {
  if (response.ok) {
    return response.json().then(json => json);
  }
  return response.json().then(json => Promise.reject(json));
};

const handleDefault = response => {
  if (response.ok) {
    return Promise.resolve({});
  }
  return Promise.reject('Unexpected content type.');
};