import { DropdownList as AdpDropdownList } from 'adp-react-components';
import React from 'react';


const emptyArray = [];

const mapData = (data = emptyArray) => Array.isArray(data)
    ? data
    : Object.keys(data).map(key => ({ key: data[key], value: key })).sort((a, b) => a.value.localeCompare(b.value));

const getValue = (data = emptyArray, value) => Array.isArray(data)
    ? value
    : Object.keys(data).filter(key => data[key] === value)[0];

const tr = (a) => '';

class DropdownList extends React.PureComponent {
    constructor(props, context) {
        super(props, context);
        this.renderDropdownList = this.renderDropdownList.bind(this);
        this.onChange = this.onChange.bind(this);
    }

    onChange(name) {
        const { onChange: notify } = this.props;
        const { data } = this.props;
        const change = Array.isArray(data) ? name : name.key;
        notify.apply({ name: this.props.name }, [change]);
    }

    renderDropdownList() {
        const { onChange, data, value, placeholder = tr("PLACEHOLDER"), ...passTrough } = this.props;
        const thisValue = getValue(data, value);
        const thisData = mapData(data);
        const filter = data.length > 10 ? (a, b) =>  a.value.toLowerCase().startsWith(b.toLowerCase()) : null;


        return (<AdpDropdownList
            placeholder={placeholder}
            value={thisValue}
            data={thisData}
            dropUp={false}
            valueField="key"
            textField="value"
            onChange={this.onChange}
            filter={filter}
            {...passTrough} />);
    }

    render() {
        return this.renderDropdownList();
    }
}

// DropdownList.propTypes = AdpDropdownList.propTypes;
// DropdownList.propTypes.data = PropTypes.any;
// DropdownList.propTypes.value = AdpDropdownList.propTypes.value;
// DropdownList.propTypes.onChange = AdpDropdownList.propTypes.onChange;
// DropdownList.propTypes.placeholder = PropTypes.string;
// DropdownList.propTypes.name = PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]);
export default DropdownList;