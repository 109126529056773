import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { withTranslator } from '../../localize/LocalizationProvider';
import { sumAmounts } from '../tools/CalcuationTools'
import { TableDataRow, TableFullWidthRow } from './PrepayTable'


const AssignmentCostsView = ({ assignmentCosts, payStatement, translator }) => {
  const translate = translator.translate;
 
  const map = (items) => {
    return items.map(i=> <TableDataRow 
                            id={i.id} 
                            parent={0}
                            label={i.label} 
                            value={i.amount} />);
  }

  const employeeGross = payStatement.grossPayAmount;
  const employerTotal = sumAmounts(assignmentCosts.employerCost.items, "amount");
  const total = sumAmounts([employeeGross, employerTotal]);

  return (<div className="prepay-assignmentCost">
            <div className="vdl-table">
              <TableFullWidthRow className="prepay-statement-headerrow" text={translate("header")} onClick={() => {}} />
              {map(assignmentCosts.employerCost.items)}
              <TableDataRow className="prepay-statement-totalrow" label={translate("Total")} value={employerTotal} />
              <TableFullWidthRow />
              <TableFullWidthRow className="prepay-statement-headerrow" text={translate("employmentCostHeader")}/>
              <TableDataRow label={`${translate("gross")}`} value={employeeGross} />
              <TableDataRow label={`${translate("Total")} ${translate("header")}`} value={employerTotal} />
              <TableDataRow className="prepay-statement-totalrow" label={`${translate("Total")}`} value={total} />

            </div>
        </div>);
}

AssignmentCostsView.propTypes = {
  assignmentCosts: PropTypes.object.isRequired,
  payStatement: PropTypes.object.isRequired
}
      
export default connect()(withTranslator(AssignmentCostsView, "AssignmentCost"));