import React from 'react';
import Icon from 'adp-react-icons/lib/fa/meh-o'
import { Button } from 'adp-react-components'

export const ErrorPage = () => {
return (
    <div className="page-error-content">
        <div className='page-error-section'>
            <Icon className='page-error__icon' />
            <div className='page-error__header-text'>SORRY
            <div className='page-error__sub-text'>Er is iets foutgegaan tijdens het verwerken van uw aanvraag.</div>
            </div>
        </div>
        <div className='page-error-section'>
            {/* <Button buttonStyle='secondary' onClick={() => { window.history.back() }}>Back</Button> */}
            <Button buttonStyle='primary' onClick={() => { window.location = '/' }}>Home</Button>
        </div>
    </div>);
}
