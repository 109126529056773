
export const createMiddleWare = callResource => store => next => action => {
  const { api, ...other } = action;
  if (typeof (api) === 'undefined') {
    return next(action);
  }

  const { type, resource } = api;
  if (typeof (type) !== 'string') {
    throw new Error('expected type to be a string');
  }

  const nextAction = { type, ...other };
  next(nextAction);

  return callResource(resource)
    .then(handleSuccess(next, { type: type + '_SUCCESS', ...other }), 
          handleError(next, { type: type + '_FAILURE', ...other }));
};

const handleSuccess = (next, action) => (response = {}) => {
  return next({ ...action, response });
};

const handleError = (next, action) => (response = 'Unexpected error') => {
  return next({ ...action, response });
};