import React from 'react'
import PropTypes from 'prop-types'
import FormInput from './FormInput'
import { culture } from '../localize/LocalizationProvider'

const noop = () => null

class TextInput extends React.Component {

  onKeyDown = (e) => {
    const { inputtype } = this.props

    if (inputtype  === 'amount' || inputtype === 'percentage')
    {
      if (e.key === '.' && culture.current === 'nl') {
        e.currentTarget.value += ','
        e.preventDefault()
      }
    }
  }

  onClickHandler = (e) => {
    e.target.select()
  }

  render()  {
    const {
      className = "",
      required,
      label = "",
      prefix = "",
      suffix = "",
      error = "",
      maxLength = 256,
      inputtype="text",
      placeHolder = "",
      onBlur = noop,
      onChange = noop,
      value = "",
      readOnly=false,
      help } = this.props

    return (<FormInput prefix={prefix} suffix={suffix} className={className} required={required} error={error} label={label} help={help}>
        <input className={`input--${inputtype} ${readOnly ? 'readonly': ''}`}
          onChange={(e) => onChange(e.target.value)}
          onBlur={() => onBlur()}
          onKeyDown={(e)=> this.onKeyDown(e)}
          onClick={(e) => this.onClickHandler(e)}
          disabled={readOnly}
          value={value}
          maxLength={maxLength}
          placeholder={placeHolder} />

      </FormInput>)
    }
  }

FormInput.propTypes = {
  label: PropTypes.string.isRequired,
  className: PropTypes.string,
  required: PropTypes.bool,
  error: PropTypes.any,
  value: PropTypes.string,
  readOnly: PropTypes.bool
}


export default TextInput