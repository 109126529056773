export const writeValueType = 'WRITEVALUE_REQUEST'
export const writeValueSucessType = 'WRITEVALUE_REQUEST_SUCCESS'
export const writeValueCompleteType = 'WRITEVALUE_REQUEST_COMPLETE'

export const componentsInfoType = 'COMPONENTINFO_REQUEST'
export const componentsInfoCompleteType = 'COMPONENTINFO_REQUEST_COMPLETE'

export const loadPagesType = 'LOADPAGES_REQUEST'
export const loadPagesTypeCompleteType = 'LOADPAGES_REQUEST_COMPLETE'

export const yearChangeType = "YEARCHANGE_REQUEST"
export const yearChangeSucessType = "YEARCHANGE_REQUEST_SUCCESS"
export const yearChangeCompleteType = "YEARCHANGE_REQUEST_COMPLETE"
export const yearChangeFailureType = "YEARCHANGE_REQUEST_FAILURE"

