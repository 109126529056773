import { culture } from '../../localize/LocalizationProvider'
import { exec, isNullOrEmptyOrWhitspace } from '../../tool/tools'
import { getCarOptions } from './carOptions'

export const inputTypes = {
  amount: 'amount',
  date: 'date',
  select: 'select',
  percentage: 'percentage',
  checkbox: 'checkbox',
  line: 'line',
  header: 'header',
  text: 'text'
}

const componentTypes = {
  Master: 'master',
  Variable: 'variable',
  Other: 'other'
}

export const getComponent = (page, id) => {
  return page.components.find(i => i.id === id)
}

export const findComponent = (pages, pageKey, componentKey) => {
  const page = pages.find(p => p.key === pageKey)
  if (!page) return undefined
  return page.components.find(i => i.key === componentKey)
}

const errorObject = (id, value, template, props) => {
  return {
    id,
    value,
    template,
    ...props
  }
}

const validateAmount = (id, value, min = null, max = null, mandatory = false) => {
  const error = (template) => errorObject(id, value, template, {
    min: min,
    max: max
  })
  if (isNullOrEmptyOrWhitspace(value) && !mandatory) return null

  if (isNullOrEmptyOrWhitspace(value))
    return errorObject(id, value, "MANDATORY", {})

  const f = culture.parseDecimal(value)
  if (isNaN(f)) return mandatory ? error("NOT_A_NUMBER_MANDATORY") : error("NOT_A_NUMBER")
  if (min !== null && f < min) return mandatory ? error("VALUE_TO_LOW_MANDATORY") : error('VALUE_TO_LOW')
  if (max !== null && f > max) return mandatory ? error("VALUE_TO_HIGH_MANDATORY") : error('VALUE_TO_HIGH')
  return null
}

const validateText = (id, value, maxLength, mandatory) => {
  if (mandatory && isNullOrEmptyOrWhitspace(value))
    return errorObject(id, value, "MANDATORY", {})

  if (value.length > maxLength) errorObject(id, value, "TEXT_TO_LONG", {})

  return null

}

export const inputErrors = (pages, showErrors, onlyDirty) => {
  let errors = []

  pages.forEach(page => {
    const e = page.components.filter(i => (!onlyDirty || i.isDirty) ? !isComponentValid(i, showErrors) : undefined)
    page.inError = e.length !== 0
    errors.push(e)
  })
  return errors.reduce(function (a, b) { return a.concat(b) })
}


const isComponentValid = (c, update = false) => {

  if (!c.visible) return true
  const error = exec(c.validation)(c.value)
  if (update) c.error = error
  return error ? false : true
}

export const isPageValid = (page) => page.components.filter(i => !isComponentValid(i, i.isDirty)).length === 0

export const countryOptions = (year) => {
  const put = (t, key, value, y) => { if (y <= year) t.push({ key, value }) }

  let options = [
    {
      key: "NL",
      value: 'Nederland'
    }]

  put(options, "BE", 'België', 2020)
  put(options, "DE", 'Een lidstaat van de EU of een EER-land', 2019)
  put(options, "AW", 'Suriname of Aruba', 2020)
  put(options, "US", 'Overig', 2019)

  return options
}

export const incomeTaxSpecOptions = (year) => {
  let options = [
    { key: 0, value: "N.v.t. (reguliere situatie)", s: 2010, e: 2099 },
    { key: 1, value: "Vakantiebonnen, minder dan 20 dagen", s: 2010, e: 2019 },
    { key: 2, value: "Vakantiebonnen, 20 dagen of meer", s: 2010, e: 2019 },
    { key: 3, value: "Aannemers van werk, thuiswerkers", s: 2010, e: 2099 },
    { key: 4, value: "Binnenlandse artiesten", s: 2010, e: 2099 },
    { key: 5, value: "Buitenlandse artiesten", s: 2010, e: 2099 },
    { key: 6, value: "Anoniementarief", s: 2010, e: 2099 },
    { key: 7, value: "Geen loonheffing", s: 2010, e: 2099 },
    { key: 8, value: "Alleen premieplichtig", s: 2010, e: 2099 },
    { key: 9, value: "Alleen belastingplichtig", s: 2010, e: 2099 },
    { key: 10, value: "Alleen premiepl. AWBZ", s: 2010, e: 2099 },
    { key: 11, value: "Alleen premiepl. ANW", s: 2010, e: 2099 },
    { key: 12, value: "Alleen premiepl. AOW/ANW", s: 2010, e: 2099 },
    { key: 13, value: "Belastingpl., niet AWBZ, groen", s: 2010, e: 2099 },
    { key: 14, value: "Belastingpl., alleen AWBZ, groen", s: 2010, e: 2099 },
  ]

  return options.filter(i => i.s <= year && i.e >= year)
}



export const getCarStartDate = (year, frequency) => {

  if (Number(frequency) === 12)
    return new Date(`${year}-01-01`)

  switch (Number(year)) {
    case 2021:
      return new Date('2021-01-04')
    case 2019:
      return new Date('2018-12-31')
    case 2020:
      return new Date('2019-12-30')
    default:
      return new Date(`${year}-01-01`)
  }
}

export const AnyPayment = (pages) => {
  const components = [
    pages[0].components.find(i => i.id === '2417'),
    pages[0].components.find(i => i.id === '295'),
    pages[3].components.find(i => i.id === 'F00'),
    pages[3].components.find(i => i.id === 'W20'),
    pages[3].components.find(i => i.id === '052'),
    pages[3].components.find(i => i.id === 'B00'),
    pages[3].components.find(i => i.id === 'J00'),
  ]

  return components.find(i => isNullOrEmptyOrWhitspace(i.value)) !== undefined
}

export const PrepayPages = [
  {
    key: 'generic',
    description: 'generic',
    pageIndex: 0,
    validate: () => isPageValid(PrepayPages[0]),
    components: [
      {
        key: 'year',
        id: '#year',
        description: 'Jaar',
        visible: true,
        inputtype: inputTypes.select,
        componentType: componentTypes.Variable,
        required: true,
        value: 2024,
        placeHolder: '',
        options: () => [2021, 2022, 2023, 2024].map(i => ({ key: i, value: i }))
      },
      {
        key: 'paymenttype',
        id: '#paymenttype',
        description: 'salarytype',
        visible: true,
        inputtype: inputTypes.select,
        componentType: componentTypes.Variable,
        required: true,
        value: 0,
        placeHolder: '',
        options: [
          {
            key: 0,
            value: 'Bruto'
          },
          {
            key: 1,
            value: 'Netto'
          }
        ]
      },
      {
        key: '2417',
        id: '2417',
        description: '2417',
        visible: true,
        inputtype: 'amount',
        componentType: componentTypes.Other,
        decimals: 2,
        maxLength: 9,
        prefix: '€',
        required: false,
        value: "",
        placeHolder: '0,00',
        validation: (value) => validateAmount("2417", value, 1, 99999.99, false),
        format: (value) => culture.formatDecimal(culture.parseDecimal(value), 2),
        onChangeNew: ({ target: { key, value }, self }) => {
          if (key === 'paymenttype') {
            return { ...self, visible: value === 0 }
          }
          return self
        }
      },
      {
        key: '295',
        id: '295',
        description: 'S295',
        visible: false,
        inputtype: 'amount',
        componentType: componentTypes.Master,
        decimals: 2,
        maxLength: 9,
        prefix: '€',
        required: false,
        value: "",
        placeHolder: '0,00',
        validation: (value) => validateAmount(2418, value, 1, 100000, false),
        format: (value) => culture.formatDecimal(culture.parseDecimal(value), 2),
        onChangeNew: ({ target: { key, value }, self }) => {
          if (key === 'paymenttype') {
            return { ...self, visible: value === 1 }
          }
          return self
        }
      },
      {
        key: '3610_0',
        id: '3610_0',
        description: '3610_0',
        inputtype: inputTypes.select,
        componentType: componentTypes.Other,
        required: true,
        visible: true,
        value: 12,
        options: [
          {
            key: 12,
            value: 'Monthly'
          },
          {
            key: 13,
            value: '4Weekly'
          }
        ],
        validation: null
      },
      {
        key: '2277',
        id: '2277',
        description: 'parttime percentage',
        inputtype: inputTypes.percentage,
        componentType: componentTypes.Other,
        decimals: 3,
        maxLength: 9,
        suffix: '%',
        visible: true,
        required: true,
        value: '100,000',
        placeHolder: '0,000',
        validation: (value) => validateAmount(2277, value, 1, 100, true),
        format: (value) => culture.formatDecimal(culture.parseDecimal(value), 3),
      },
      {
        key: '2128',
        id: '2128',
        description: '2128',
        visible: true,
        required: true,
        inputtype: 'date',
        value: new Date(1985, 0),
      },
      {
        key: 'cao',
        id: '#cao',
        description: 'cao',
        visible: true,
        required: true,
        value: '9999',
        inputtype: inputTypes.select,
        options: [],
        onYearChange: ({ item, pages, caos }) => {
          const options = caos.map(i => ({
            "key": i.key,
            "value": i.name
          }))
          const cao = options.find(i => i.key === item.value)
          const value = cao ? item.value : options[0].key
          return { ...item, value, options }
        }
      }
    ]
  },
  {
    key: 'financial',
    description: 'financial fiscaal',
    pageIndex: 1,
    validate: () => isPageValid(PrepayPages[1]),
    components: [
      {
        key: '#incomeSource',
        id: '#incomeSource',
        description: 'incomeSource',
        visible: true,
        inputtype: inputTypes.select,
        componentType: componentTypes.Other,
        required: true,
        value: 0,
        remark: "Part of 2474",
        placeHolder: '',
        options: [
          {
            key: 0,
            value: 'Arbeid'
          },
          {
            key: 1,
            value: 'Uitkering'
          }
        ]
      },
      {
        key: '2039',
        id: '2039',
        description: 'Woonland',
        visible: true,
        inputtype: inputTypes.select,
        componentType: componentTypes.Other,
        required: true,
        value: "NL",
        placeHolder: '',
        options: [
          {
            key: "NL",
            value: 'Nederland'
          },
          {
            key: "DE",
            value: "Een lidstaat van de EU of een EER-land"
          },
          {
            key: "US",
            value: "Overig"
          }
        ],
        onYearChange: ({ item, fiscalYear }) => {
          const options = countryOptions(fiscalYear)
          return { ...item, options }
        }
      },
      {
        key: 'incomeTaxSpec',
        id: '#incomeTaxSpec',
        description: 'incomeTaxSpec',
        visible: true,
        inputtype: inputTypes.select,
        componentType: componentTypes.Other,
        required: true,
        value: 0,
        remark: "Part of 2471",
        placeHolder: '',
        options: [
          { key: 0, value: "N.v.t. (reguliere situatie)" },
          { key: 1, value: "Vakantiebonnen, minder dan 20 dagen" },
          { key: 2, value: "Vakantiebonnen, 20 dagen of meer" },
          { key: 3, value: "Aannemers van werk, thuiswerkers" },
          { key: 4, value: "Binnenlandse artiesten" },
          { key: 5, value: "Buitenlandse artiesten" },
          { key: 6, value: "Anoniementarief" },
          { key: 7, value: "Geen loonheffing" },
          { key: 8, value: "Alleen premieplichtig" },
          { key: 9, value: "Alleen belastingplichtig" },
          { key: 10, value: "Alleen premiepl. AWBZ" },
          { key: 11, value: "Alleen premiepl. ANW" },
          { key: 12, value: "Alleen premiepl. AOW/ANW" },
          { key: 13, value: "Belastingpl., niet AWBZ, groen" },
          { key: 14, value: "Belastingpl., alleen AWBZ, groen" }
        ],
        onYearChange: ({ item, fiscalYear }) => {
          const options = incomeTaxSpecOptions(fiscalYear)
          return { ...item, options }
        }
      },
      {
        key: "2818",
        id: "2818",
        description: "Dagtabel toepassen",
        visible: true,
        inputtype: inputTypes.checkbox,
        componentType: componentTypes.Other,
        falseValue: 0,
        trueValue: 1
      },
      {
        key: "193",
        id: "193",
        description: "Betaalde dagen",
        visible: false,
        inputtype: inputTypes.amount,
        componentType: componentTypes.Variable,
        value: "23",
        decimals: 0,
        maxLength: 2,
        required: true,
        onChangeNew: ({ target: { key, value }, self }) =>
          (key === '2818')
            ? { ...self, visible: (value === 1) }
            : (key === '3610_0')
              ? { ...self, value: (value === 12 ? 23 : 20) }
              : self,
        validation: (value) => validateAmount(193, value, 0, 31, true),
        format: (value) => culture.formatDecimal(culture.parseDecimal(value), 0)
      },
      {
        key: "2483",
        id: "2483",
        description: "Heffingskortingen",
        visible: true,
        inputtype: inputTypes.checkbox,
        componentType: componentTypes.Other,
        falseValue: 0,
        trueValue: 1,
        value: 1
      },
      {
        key: '202',
        id: '202',
        description: 'Afwijkend percentage Bijzondere Beloningen',
        visible: true,
        inputtype: 'percentage',
        componentType: componentTypes.Master,
        required: false,
        value: "",
        decimals: 2,
        maxLength: 7,
        suffix: '%',
        remark: "",
        placeHolder: "49,25",
        changeHandler: (value, self, fiscalYear) => {
          const values = {
            2021: '47,55',
            2020: '49,02',
            2019: '49,25',
            'default': '40,85'
          }
          if (isNullOrEmptyOrWhitspace(value)) {
            const placeHolder = values[fiscalYear] ? values[fiscalYear] : values['default']
            return { ...self, placeHolder }
          }
          return { ...self, placeHolder: 'automatisch' }
        },
        onChangeNew: ({ target: { key, value }, self, fiscalYear }) =>
          key === '2417' || key === '295'
            ? self.changeHandler(value, self, fiscalYear)
            : self,
        onYearChange: ({ item, pages, fiscalYear }) => {
          const gross = pages[0].components.find(i => i.id === '2417')
          const nett = pages[0].components.find(i => i.id === '295')

          return gross.visible
            ? item.changeHandler(gross.value, item, fiscalYear)
            : item.changeHandler(nett.value, item, fiscalYear)
        },
        validation: (value) => validateAmount(202, value, 0, 100, false),
        format: (value) => culture.formatDecimal(culture.parseDecimal(value), 2),
      },
      {
        key: '2479',
        id: '2479',
        description: '30% regeling',
        visible: true,
        inputtype: inputTypes.checkbox,
        componentType: componentTypes.Other,
        trueValue: 3,
        // required: true
      },
      {
        key: '2457',
        id: '2457',
        description: 'Aftopping overgangsrecht',
        visible: false,
        inputtype: inputTypes.checkbox,
        componentType: componentTypes.Other,
        trueValue: 0,
        falseValue: 1,
        value: 0,
        onChangeNew: ({ target: { key, value }, self, fiscalYear }) =>
          key === '2479' ? { ...self, visible: value === 3 } : self
      },
      {
        key: "spacer2906",
        id: "#spacer2906",
        visible: true,
        inputtype: inputTypes.line
      },
      {
        key: '2906',
        id: '2906',
        description: 'Auto van de zaak, opties worden tijdens opstart geladen',
        visible: true,
        required: false,
        inputtype: inputTypes.select,
        componentType: componentTypes.Other,
        value: "",
        options: [],
        onYearChange: ({ item, fiscalYear }) => {
          const options = getCarOptions(fiscalYear)
          return { ...item, options, value: "" }
        }
      },
      {
        key: '2892',
        id: '2892',
        description: 'ingangsdatum auto vd zaak',
        visible: false,
        inputtype: inputTypes.date,
        componentType: componentTypes.Other,
        required: true,
        value: new Date(2019, 0),
        onYearChange: ({ item, fiscalYear, frequency }) => {
          return !item.isDirty ? { ...item, value: getCarStartDate(fiscalYear, frequency), visible: false } : { ...item, visible: false }
        },
        onChangeNew: ({ target: { key, value }, self }) =>
          (key === '2906') ? { ...self, visible: value !== "" } : self,
        validation: (value) => {
          const freq = getComponent(PrepayPages[0], '3610_0').value
          const year = getComponent(PrepayPages[0], '#year').value
          const getMaxDate = (d, days) => {
            d.setDate(d.getDate() + days)
            return d
          }
          if (freq === 13) {
            if (new Date(value) > getMaxDate(getCarStartDate(year, freq), 27))
              return errorObject('2892', value, `VALUE_2829_TO_HIGH_${year}`)
          }

          if (!value || value === "") return errorObject('2892', value, "MANDATORY", {})

          return null
        }
      },
      {
        key: '2891',
        id: '2891',
        description: 'Waarde auto',
        visible: false,
        inputtype: 'amount',
        componentType: componentTypes.Other,
        decimals: 0,
        maxLength: 11,
        prefix: '€',
        required: true,
        value: "",
        placeHolder: '0',
        onChangeNew: ({ target: { key, value }, self }) =>
          (key === '2906') ? { ...self, visible: value !== "" } : self,
        validation: (value) => validateAmount(2891, value, 1, 1500000, true),
        format: (value) => culture.formatDecimal(culture.parseDecimal(value), 0),
        onYearChange: ({ item }) => {
          return { ...item, visible: false }
        }
      },
      {
        key: '601',
        id: '601',
        description: 'Vergoeding auto',
        visible: false,
        inputtype: 'amount',
        componentType: componentTypes.Variable,
        decimals: 2,
        maxLength: 9,
        prefix: '€',
        required: false,
        value: "",
        placeHolder: '0,00',
        onChangeNew: ({ target: { key, value }, self }) =>
          (key === '2906') ? { ...self, visible: value !== "" } : self,
        validation: (value) => validateAmount(601, value, 1, 100000, false),
        format: (value) => culture.formatDecimal(culture.parseDecimal(value), 2),
        onYearChange: ({ item }) => {
          return { ...item, visible: false }
        }
      },
      {
        key: "spacer2906",
        id: "#spacer2906",
        visible: true,
        inputtype: inputTypes.line
      },
      {
        key: '2082',
        id: '2082',
        description: 'Fiets van de zaak opties',
        visible: true,
        required: false,
        inputtype: inputTypes.select,
        componentType: componentTypes.Other,
        value: 0,
        options: [
          { key: 0, value: "Geen fiets van de zaak" },
          { key: 1, value: "Fiets van de zaak, met fiscale bijtelling" },
          { key: 2, value: "Fiets van de zaak, zonder fiscale bijtelling" }
        ]
      },

      {
        key: '2940',
        id: '2940',
        description: 'ingangsdatum',
        visible: false,
        inputtype: inputTypes.date,
        componentType: componentTypes.Other,
        required: true,
        value: new Date(2019, 0),
        onYearChange: ({ item, fiscalYear, frequency }) => {
          return !item.isDirty ? { ...item, value: getCarStartDate(fiscalYear, frequency) } : { ...item }
        },
        onChangeNew: ({ target: { key, value }, self }) =>
          (key === '2082') ? { ...self, visible: value !== 0 } : self,
        validation: (value) => {
          const freq = getComponent(PrepayPages[0], '3610_0').value
          const year = getComponent(PrepayPages[0], '#year').value
          const getMaxDate = (d, days) => {
            d.setDate(d.getDate() + days)
            return d
          }
          if (freq === 13) {
            if (new Date(value) > getMaxDate(getCarStartDate(year, freq), 27))
              return errorObject('2940', value, `VALUE_2829_TO_HIGH_${year}`)
          }
          return null
        }
      },
      {
        key: '2942',
        id: '2942',
        description: 'Waarde fiets',
        visible: false,
        inputtype: 'amount',
        componentType: componentTypes.Other,
        decimals: 0,
        maxLength: 7,
        prefix: '€',
        required: true,
        value: "",
        placeHolder: '0',
        onChangeNew: ({ target: { key, value }, self }) =>
          (key === '2082') ? { ...self, visible: value !== 0 } : self,
        validation: (value) => validateAmount(2942, value, 1, 1500000, true),
        format: (value) => culture.formatDecimal(culture.parseDecimal(value), 0),
      },
      {
        key: 'A4F',
        id: 'A4F',
        description: 'Bijdrage fiets',
        visible: false,
        inputtype: 'amount',
        componentType: componentTypes.Other,
        decimals: 2,
        maxLength: 11,
        prefix: '€',
        value: "",
        placeHolder: '0,00',
        onChangeNew: ({ target: { key, value }, self }) =>
          (key === '2082') ? { ...self, visible: value !== 0 } : self,
        validation: (value) => validateAmount(2942, value, 1, 1500000, false),
        format: (value) => culture.formatDecimal(culture.parseDecimal(value), 2),
      },
    ]
  },
  {
    key: 'sv',
    description: 'sociale verzekeringen',
    pageIndex: 2,
    validate: () => isPageValid(PrepayPages[2]),
    components: [
      {
        key: '2242',
        id: '2242',
        description: 'Zorgverzekeringswet',
        visible: true,
        inputtype: inputTypes.select,
        componentType: componentTypes.Other,
        required: true,
        value: 'K',
        options: [
          { key: 'A', value: 'A - Nee, want geen AWBZ' },
          { key: 'B', value: 'B - Nee, militair ambtenaar' },
          { key: 'K', value: 'K - Ja, normaal tarief werkgeversheffing' },
          { key: 'L', value: 'L - Ja, tarief zeelieden' },
          { key: 'M', value: 'M - Ja, ingehouden bijdrage' }
        ]
      },
      {
        key: '70033_70_25_22',
        id: '70033_70_25_22',
        description: 'Percentage werkgeversheffing (ZVW)',
        inputtype: inputTypes.percentage,
        componentType: componentTypes.Other,
        decimals: 3,
        maxLength: 9,
        readOnly: true,
        suffix: '%',
        visible: true,
        required: false,
        IsCalcuationRule: true,
        value: '6,95',
        placeHolder: '0,000',
        validation: (value) => validateAmount("70033_22", value, 1, 100, true),
        format: (value) => culture.formatDecimal(culture.parseDecimal(value), 3),
        onYearChange: ({ item, fiscalYear }) => {
          const values = {
            2024: '6,57',
            2023: '6,68',
            2022: '6,75',
            2021: '7,00',
            2019: '6,95',
            2018: '6,90',
            'default': '6,70'
          }
          return { ...item, value: values[fiscalYear] ? values[fiscalYear] : values['default'] }
        }
      },
      {
        key: '70033_70_25_23',
        id: '70033_70_25_23',
        description: 'Percentage werkgeversheffing (ZVW)',
        inputtype: inputTypes.percentage,
        componentType: componentTypes.Other,
        decimals: 3,
        maxLength: 9,
        readOnly: true,
        suffix: '%',
        visible: true,
        required: false,
        IsCalcuationRule: true,
        value: '5,70',
        placeHolder: '0,000',
        validation: (value) => validateAmount("70033_70_25_23", value, 1, 100, true),
        format: (value) => culture.formatDecimal(culture.parseDecimal(value), 3),
        onYearChange: ({ item, fiscalYear }) => {
          const values = {
            2024: '5,32',
            2023: '5,43',
            2022: '5,50',
            2021: '5,75',
            2019: '5,70',
            2018: '5,65',
            'default': '5,45'
          }
          return { ...item, value: values[fiscalYear] ? values[fiscalYear] : values['default'] }
        }
      },
      {
        key: "spacer2449",
        id: "#spacer2449",
        visible: true,
        inputtype: inputTypes.line
      },
      {
        key: "2208",
        id: "2208",
        description: "Werkloosheidswet (WW 2020) ",
        visible: false,
        inputtype: inputTypes.select,
        componentType: componentTypes.Other,
        value: 1,
        options: [
          { key: -1, value: "Geen" },
          { key: 1, value: "Lage premie" },
          { key: 2, value: "Hoge premie" }
        ],
        onChangeNew: ({ target: { key, value }, self }) =>
          (key === '2906') ? { ...self, visible: value !== "" } : self,

        onYearChange: ({ item, fiscalYear }) => ({ ...item, visible: fiscalYear > 2019 }),
      },
      {
        key: '70030_70_25_11',
        id: '#70030_70_25_11',
        description: 'Percentage WW werkgever',
        inputtype: inputTypes.percentage,
        componentType: componentTypes.Other,
        decimals: 3,
        maxLength: 9,
        readOnly: true,
        suffix: '%',
        visible: true,
        required: false,
        IsCalcuationRule: false,
        value: '5,70',
        placeHolder: '0,000',
        changeHandler: (self, wwValue, fiscalYear) => {
          const valueItems = {
            2024: ["2,64", "7,64"],
            2023: ["2,64", "7,64"],
            2022: ["2,70", "7,70"],
            2021: ["2,70", "7,70"],
            2020: ["2,94", "7,94"],
            'default': ["2,64", "7,64"]
          }
          if (wwValue < 0)
            return { ...self, value: '', visible: false }

          const values = (valueItems[fiscalYear] ? valueItems[fiscalYear] : valueItems['default'])
          const value = values[wwValue - 1]
          return { ...self, value, visible: true }
        },
        onYearChange: ({ item, pages, fiscalYear }) => {
          const wwwSelect = findComponent(pages, 'sv', '2208')
          return item.changeHandler(item, wwwSelect.value, fiscalYear)
        },
        onChangeNew: ({ target: { key, value }, self, fiscalYear }) => {
          return (key === '2208') ? self.changeHandler(self, value, fiscalYear) : self
        },
        validation: (value) => validateAmount("70030_70_25_11", value, 1, 100, true),
        format: (value) => culture.formatDecimal(culture.parseDecimal(value), 3),
      },
      {
        key: "spacerWIA",
        id: "#spacerWIA",
        visible: true,
        inputtype: inputTypes.line,
      },
      {

        key: "2450",
        id: "2450",
        description: "Aof/Wko verzekerd",
        componentType: componentTypes.Other,
        visible: true,
        inputtype: inputTypes.checkbox,
        falseValue: 0,
        trueValue: 1,
        value: 1,
      },
      {

        key: "70031_70_25_40",
        id: "70031_70_25_40",
        description: "Aof/Wko percentage",
        componentType: componentTypes.Other,
        action: 'Update',
        visible: true,
        IsCalcuationRule: true,
        inputtype: inputTypes.select,
        value: 2,
        options: [
          { key: 1, value: "Aof laag voor kleine werkgevers" },
          { key: 2, value: "Aof hoog voor (middel)grote werkgevers" }
        ],
        onChangeNew: ({ target: { key, value }, self, fiscalYear }) =>
          (key === '2450') ? { ...self, visible: (value === 1 && fiscalYear >= 2022) } : self,
        onYearChange: ({ item, pages, fiscalYear }) => {
          const component = findComponent(pages, 'sv', '2450')
          const visible = component.value === 1 && fiscalYear >= 2022
          return { ...item, visible }
        }
      },
      // {
      //   key: "spacer2450",
      //   id: "#spacer2450",
      //   visible: true,
      //   inputtype: inputTypes.header,
      //   componentType: componentTypes.Other,
      //   falseValue: 1,
      //   trueValue: 0,
      //   required: false,
      //   value: 1,
      //   onChange: ({ value, pages }) => {
      //     const show = value === 1
      //     getComponent(pages[2], '#70014_060_70_25').visible = show
      //     getComponent(pages[2], '#70031_063_70_25').visible = show
      //     getComponent(pages[2], '#70031_060_70_25').visible = show
      //   }
      // },
      {
        key: "spacerWHK",
        id: "#spacerWHK",
        availableFrom: 2022,
        visible: false,
        inputtype: inputTypes.line,
        // componentType: componentTypes.Other,
        // falseValue: 1,
        // trueValue: 0,
        // required: false,
        // value: 1,
        // onChange: ({ value, pages }) => {
        //   const show = value === 1
        //   getComponent(pages[2], '70014_060_70_25').visible = show
        //   getComponent(pages[2], '70031_063_70_25').visible = show
        //   getComponent(pages[2], '70031_060_70_25').visible = show
        // }
      },
      {
        key: '70014_70_25_60',
        id: '70014_70_25_60',
        action: 'Update',
        description: 'Afdrachtspercentage ZW-flex',
        inputtype: inputTypes.percentage,
        componentType: componentTypes.Other,
        readOnly: false,
        suffix: '%',
        visible: true,
        required: false,
        IsCalcuationRule: true,
        decimals: 3,
        maxLength: 6,
        placeHolder: '0,000',
        validation: (value) => validateAmount("70014_70_25_60", value, 0, 100, false),
        format: (value) => culture.formatDecimal(culture.parseDecimal(value), 3),
      },
      {
        key: '70031_70_25_60',
        id: '70031_70_25_60',
        action: 'Update',
        description: 'WGA eigen risico drager afdracht',
        inputtype: inputTypes.percentage,
        componentType: componentTypes.Other,
        readOnly: false,
        suffix: '%',
        visible: true,
        required: false,
        IsCalcuationRule: true,
        decimals: 3,
        maxLength: 6,
        placeHolder: '0,000',
        validation: (value) => {
          const error = validateAmount('70031_70_25_60', value, 0, 5, false)
          if (error === null) {
            const otherValue = culture.parseDecimal(getComponent(PrepayPages[2], '70031_70_25_63').value || '0')
            const f = culture.parseDecimal(value || 0)
            if (otherValue > f)
              return errorObject('70031_70_25_60', value, 'WGA_VALUE_TO_HIGH')
          }
          return error

        },
        format: (value) => culture.formatDecimal(culture.parseDecimal(value), 3),
      },
      {
        key: '70031_70_25_63',
        id: '70031_70_25_63',
        action: 'Update',
        description: 'WGA eigen risico drager inhouding',
        inputtype: inputTypes.percentage,
        componentType: componentTypes.Other,
        readOnly: false,
        suffix: '%',
        visible: true,
        required: false,
        IsCalcuationRule: true,
        decimals: 3,
        maxLength: 6,
        placeHolder: '0,000',
        validation: (value) => validateAmount('70031_70_25_63', value, 0, 5, false),
        format: (value) => culture.formatDecimal(culture.parseDecimal(value), 3),
      },
      {
        key: "spacer2451",
        id: "#spacer2451",
        visible: true,
        inputtype: inputTypes.line
      },
      {
        key: "2451",
        id: "2451",
        description: "Sociaal Fonds",
        visible: true,
        inputtype: inputTypes.checkbox,
        componentType: componentTypes.Other,
        // required: true
        falseValue: 0,
        trueValue: 1,
        value: 0
      },
      {
        key: '70032-70-25-46',
        id: '70032-70-25-46',
        description: 'Percentage Afdracht Sociaal Fonds',
        inputtype: inputTypes.percentage,
        componentType: componentTypes.Other,
        decimals: 3,
        maxLength: 9,
        suffix: '%',
        visible: false,
        required: false,
        IsCalcuationRule: true,
        value: '',
        placeHolder: '0,000',
        onChangeNew: ({ target: { key, value }, self }) =>
          (key === '2451') ? { ...self, visible: (value === 1) } : self,
        validation: (value) => validateAmount("70032-70-25-46", value, 0, 100, false),
        format: (value) => culture.formatDecimal(culture.parseDecimal(value), 3),
      },
      {
        key: '70032-70-25-43',
        id: '70032-70-25-43',
        description: 'Percentage Inhouding Sociaal Fonds',
        inputtype: inputTypes.percentage,
        componentType: componentTypes.Other,
        decimals: 3,
        maxLength: 9,
        suffix: '%',
        visible: false,
        required: false,
        IsCalcuationRule: true,
        value: '',
        placeHolder: '0,000',
        onChangeNew: ({ target: { key, value }, self }) =>
          (key === '2451') ? { ...self, visible: (value === 1) } : self,
        validation: (value) => validateAmount("70032-70-25-43", value, 0, 100, false),
        format: (value) => culture.formatDecimal(culture.parseDecimal(value), 3),
      },
      {
        key: '70032-70-25-18',
        id: '70032-70-25-18',
        description: 'Max dagloon Sociaal fonds',
        visible: false,
        required: false,
        IsCalcuationRule: true,
        inputtype: 'amount',
        componentType: componentTypes.Variable,
        decimals: 2,
        maxLength: 9,
        prefix: '€',
        value: "0",
        placeHolder: '0,00',
        onChangeNew: ({ target: { key, value }, self }) =>
          (key === '2451') ? { ...self, visible: (value === 1) } : self,
        validation: (value) => validateAmount("70032-70-25-18", value, 0, 10000, false),
        format: (value) => culture.formatDecimal(culture.parseDecimal(value), 2),
      },
    ]
  },
  {
    key: 'allowances',
    description: 'Vergoedingen',
    pageIndex: 3,
    validate: () => isPageValid(PrepayPages[3]),
    components: [
      {
        key: 'W20',
        id: 'W20',
        description: 'Loon in natura',
        visible: true,
        inputtype: 'amount',
        componentType: componentTypes.Variable,
        decimals: 2,
        maxLength: 9,
        prefix: '€',
        value: "",
        placeHolder: '0,00',
        validation: (value) => validateAmount("W20", value, 1, 100000, false),
        format: (value) => culture.formatDecimal(culture.parseDecimal(value), 2),
      },
      {
        key: '052',
        id: '052',
        description: 'Vakantiegeld',
        visible: true,
        inputtype: 'amount',
        componentType: componentTypes.Variable,
        decimals: 2,
        maxLength: 11,
        prefix: '€',
        required: false,
        value: "",
        placeHolder: '0,00',
        validation: (value) => validateAmount("092", value, 1, 1000000, false),
        format: (value) => culture.formatDecimal(culture.parseDecimal(value), 2),
      },
      {
        key: 'J00',
        id: 'J00',
        description: 'Bijzondere beloningen',
        visible: true,
        inputtype: 'amount',
        componentType: componentTypes.Variable,
        decimals: 2,
        maxLength: 11,
        prefix: '€',
        value: "",
        placeHolder: '0,00',
        validation: (value) => validateAmount("092", value, 1, 1000000, false),
        format: (value) => culture.formatDecimal(culture.parseDecimal(value), 2),
      },
      {
        key: 'B00',
        id: 'B00',
        description: 'Onbelaste vergoeding',
        visible: true,
        inputtype: 'amount',
        componentType: componentTypes.Variable,
        decimals: 2,
        maxLength: 9,
        prefix: '€',
        value: "",
        placeHolder: '0,00',
        validation: (value) => validateAmount("092", value, 1, 100000, false),
        format: (value) => culture.formatDecimal(culture.parseDecimal(value), 2),
      },
      {
        key: 'F00',
        id: 'F00',
        description: 'Belaste vergoeding',
        visible: true,
        inputtype: 'amount',
        componentType: componentTypes.Variable,
        decimals: 2,
        maxLength: 9,
        prefix: '€',
        required: false,
        value: "",
        placeHolder: '0,00',
        validation: (value) => validateAmount("092", value, 1, 100000, false),
        format: (value) => culture.formatDecimal(culture.parseDecimal(value), 2),
      }
    ]
  },
  {
    key: 'deductions',
    description: 'Inhoudingen',
    pageIndex: 4,
    validate: () => isPageValid(PrepayPages[4]),
    components: [
      {
        key: '092',
        id: '092',
        description: 'Pensioen',
        visible: true,
        inputtype: 'amount',
        componentType: componentTypes.Variable,
        decimals: 2,
        maxLength: 9,
        prefix: '€',
        value: "",
        placeHolder: '0,00',
        validation: (value) => validateAmount("092", value, 1, 100000, false),
        format: (value) => culture.formatDecimal(culture.parseDecimal(value), 2),
      },
      {
        key: '212',
        id: '212',
        description: 'Aanvullend Pensioen',
        visible: true,
        inputtype: 'amount',
        componentType: componentTypes.Variable,
        decimals: 2,
        maxLength: 9,
        prefix: '€',
        value: "",
        placeHolder: '0,00',
        validation: (value) => validateAmount("092", value, 1, 100000, false),
        format: (value) => culture.formatDecimal(culture.parseDecimal(value), 2),
      },
      {
        key: 'M00',
        id: 'M00',
        description: 'Netto inhoudingen',
        visible: true,
        inputtype: 'amount',
        componentType: componentTypes.Variable,
        decimals: 2,
        maxLength: 9,
        prefix: '€',
        value: "",
        placeHolder: '0,00',
        validation: (value) => validateAmount("092", value, 1, 100000, false),
        format: (value) => culture.formatDecimal(culture.parseDecimal(value), 2),
      },
      {
        key: '218',
        id: '218',
        description: 'Bruto inhoudingen Tabel',
        visible: true,
        inputtype: 'amount',
        componentType: componentTypes.Variable,
        decimals: 2,
        maxLength: 9,
        prefix: '€',
        value: "",
        placeHolder: '0,00',
        validation: (value) => validateAmount("092", value, 1, 100000, false),
        format: (value) => culture.formatDecimal(culture.parseDecimal(value), 2),
      },
      {
        key: '186',
        id: '186',
        description: 'Bruto inhoudingen Bijzondere Beloningen',
        visible: true,
        inputtype: 'amount',
        componentType: componentTypes.Variable,
        decimals: 2,
        maxLength: 9,
        prefix: '€',
        value: "",
        placeHolder: '0,00',
        validation: (value) => validateAmount("092", value, 1, 100000, false),
        format: (value) => culture.formatDecimal(culture.parseDecimal(value), 2),
      }
    ]
  },
  {
    key: "cao",
    description: "CAO Pagina",
    pageIndex: 5,
    validate: () => isPageValid(PrepayPages[5]),
    changeHandler: (value, self, caos) => {
      const caoInfo = caos.find(i => i.key === value)
      if (!caoInfo || !caoInfo.fields || caoInfo.fields.length === 0) {
        return { ...self, components: [] }
      }

      const title = caoInfo.name + " cao"

      const components = caoInfo.fields.map(i => {
        const org = self.components.find(x => x.key === i.key)
        return {
          key: i.key,
          id: i.key,
          inputtype: i.inputType.toLowerCase(),
          label: i.label,
          componentType: i.componentType,
          options: i.options,
          value: org && org.isDirty ? org.value : i.value ,
          isDirty: org && org.isDirty,
          help: i.help,
          placeHolder: i.placeHolder,
          visible: i.visible,
          onChange: i.onChange === undefined ? undefined : new Function('value', 'pages', 'caos', i.onChange),
          format: i.decimals ? (value) => culture.formatDecimal(culture.parseDecimal(value), i.decimals) : undefined
        }
      })
      return { ...self, components, title }
    },
    onChangeNew: ({ target: { key, value }, self, caos }) =>
      (key === 'cao') ? self.changeHandler(value, self, caos) : self,
    onYearChange: ({ self, pages, caos }) => {
      const caoBox = findComponent(pages, 'generic', 'cao')
      return self.changeHandler(caoBox.value, self, caos)
    },
    components: []
  },
  {
    key: "NAW",
    description: "NAW Pagina",
    pageIndex: 6,
    visible: true,
    validate: () => isPageValid(PrepayPages[6]),
    components: [
      {
        key: '2019',
        id: '2019',
        description: 'Titel',
        visible: true,
        inputtype: inputTypes.text,
        componentType: componentTypes.Variable,
        maxLength: 6,
        value: "",
        placeHolder: '',
        noUpdate: true,
        validation: (value) => validateText("2019", value, 25, false)
      },
      {
        key: '2018',
        id: '2018',
        description: 'Voornaam',
        visible: true,
        inputtype: inputTypes.text,
        componentType: componentTypes.Variable,
        maxLength: 25,
        value: "",
        placeHolder: '',
        noUpdate: true,
        validation: (value) => validateText("2018", value, 25, false)
      },
      {
        key: '2017',
        id: '2017',
        description: 'Achternaam',
        visible: true,
        inputtype: inputTypes.text,
        componentType: componentTypes.Variable,
        maxLength: 30,
        value: "",
        placeHolder: '',
        noUpdate: true,
        validation: (value) => validateText("2017", value, 30, false)
      }


    ]
  }
]