import React from 'react'
import PropTypes from 'prop-types'
import CaretDown from 'adp-react-icons/lib/fa/caret-down'
import CaretUp from 'adp-react-icons/lib/fa/caret-up'

const noob = () => null;
class SubAccordion extends React.Component {
  constructor(props) {
    super(props);
    const { expanded = false } = props;
    this.state = { expanded };
  }

  expand = () => {
    const { expanded } = this.state;
    const { onChange = noob } = this.props;
    this.setState({ expanded: !expanded });
    onChange(expanded);
  }

  render = () => {
    const { className } =  this.props;
    const { expanded } = this.state;

    return (<div onClick={this.expand} className={className}>
              <span  className='subAccordion_expand-icon'>
                <CaretUp style={{ "display": `${expanded ? 'none' : 'inline'}` }}/>
                <CaretDown style={{ "display": `${!expanded ? 'none' : 'inline'}` }}/>
              </span>
              <div className='subAccordionLabel'>
                <span>{this.props.label}</span>
              </div>

            </div>);
  }
}

SubAccordion.propTypes = {
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  expanded: PropTypes.bool
}

export default SubAccordion;