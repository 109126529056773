export const isFunction = (functionToCheck) => {
    return functionToCheck && {}.toString.call(functionToCheck) === '[object Function]';
   };

export const exec = (f) => {
    if (isFunction(f))
      return f;
    return () => null;
  }


export const isNullOrEmptyOrWhitspace = (value) => value === null || value === undefined || (value + " ").trim().length === 0;
