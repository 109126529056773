/*eslint-disable no-unused-vars */

const toastrs = {
    SUCCESS: {
        type: "success",
    },
    FAILURE: {
        type: "error",
    }
};

const suspended = {};

const reducerName = (state = [], action) => {
    
    const error = (action.response || {}).error || null;
    
    if (error !== null) {
        const message = typeof(error) === "object" ? error.message : undefined;
        const messageId = typeof(error) === "string" ? error.message : undefined;
        return [...state, { id: new Date().getTime(), type: 'error', messageId, message}]
    }
    return state;
};

export default reducerName;
