import React from 'react';
import { Route } from 'react-router';
import Layout from './appplication/Layout';
import Home from './appplication/Home';
import ToastrContainer from './appplication/components/ToastrContainer';
import { ErrorPage } from './ErrorPage';

export default () => (
  <Route component={(props) =>
    <Layout>
        <Route path="/error" component={ErrorPage} />      
        <Route exact path='/' component={Home} />
      <ToastrContainer />
    </Layout>}
    />
);