import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { withTranslator } from '../../localize/LocalizationProvider'
import { sumAmounts } from '../tools/CalcuationTools'
import { TableDataRow, TableFullWidthRow } from './PrepayTable'

const OtherPayDivs = ({ items, translate }) => {
  return items.map(o => (<React.Fragment>
        <TableFullWidthRow className="prepay-statement-headerrow" text={o.sectionLabelName} />
        {o.otherPayDetail.map(i=> (<TableDataRow label={i.labelName} value={i.tableAmount} />))}
        <TableDataRow className="prepay-statement-totalrow" label={translate("Total")} value={sumAmounts(o.otherPayDetail, "tableAmount")} />
        <TableFullWidthRow />
        </React.Fragment>));
}

const PayStatementView = ({ payStatement, translator }) => {
  const translate = translator.translate;

  const mouseover = (e, id) => {
    console.log("MouseOver", id);
  }
  const mouseout = (e, id) => {
    //  somehow we have to make it popup on the Paylens
    console.log("MouseOut", id);
  }

  const map = (items) => {
    return items.map(i=> <TableDataRow
                            key={i.id}
                            id={i.id}
                            parent={0}
                            label={i.labelName}
                            value={i.amount}
                            onMouseOver={mouseover}
                            onMouseOut={mouseout} />);
  }

  return (<div className="prepay-statement">
            <div className="vdl-table">

              <TableFullWidthRow className="prepay-statement-headerrow" text={translate("earnings")} />
              {map(payStatement.earnings)}
              <TableDataRow className="prepay-statement-totalrow" label={translate("Total")} value={payStatement.grossPayAmount} />
              <TableFullWidthRow />

              <TableFullWidthRow className="prepay-statement-headerrow" text={translate("deductions")} />
              {map(payStatement.deductions)}
              <TableDataRow className="prepay-statement-totalrow" label={translate("Total")} value={sumAmounts(payStatement.deductions, "amount")} />
              <TableFullWidthRow />

              <OtherPayDivs items={payStatement.otherPay} translate={translate} />

              <TableDataRow label={`${translate("Total")} ${translate("earnings")}`} value={payStatement.grossPayAmount} />
              <TableDataRow label={`${translate("Total")} ${translate("deductions")}`} value={sumAmounts(payStatement.deductions, "amount")} />
              <TableDataRow className="prepay-statement-totalrow" label={`${translate("nett")}`} value={payStatement.netPayAmount} />
          </div>
        </div>);
}

PayStatementView.propTypes = {
  payStatement: PropTypes.object.isRequired
}

export default connect()(withTranslator(PayStatementView, "PayStatement"));