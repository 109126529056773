import { connect } from 'react-redux';
import Toastrs from './Toastrs';

function mapStateToProps(state) {
    let { toastrs } = state;
    toastrs = Array.isArray(toastrs) ? toastrs : [];
    return {
        toastrs
    };
}

export default connect(mapStateToProps, {})(Toastrs);