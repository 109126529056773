import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import TextInput from '../../components/TextInput'
import { withTranslator, culture } from '../../localize/LocalizationProvider'
import DropdownList from '../../components/DropdownList'
import { DatePicker, ToggleSwitch } from 'adp-react-components'
import FormInput from '../../components/FormInput'
import { isFunction } from '../../tool/tools'
import HelpComponent from '../../components/help'
import { inputTypes } from '../../store/defaults/componentState'
import SubAccordion from './SubAccordion'


class InputComponent extends React.Component {
  constructor(props){
    super(props)
    this.state = {value: this.props.value || '', error: null}
  }

  componentDidMount() {
    this.setState({ ...this.state, value: this.props.value })
  }

  onBlur = () => {
    const { id, pageIndex, format = null, onBlurHandler, noUpdate = false  } = this.props
    let { value = "", dirty } = this.state
    value  = format !== null ? format(value) : value
    if (dirty && !noUpdate) {
      dirty = false
      onBlurHandler(pageIndex, id, value)
    }
  }

  onChange =(newValue) => {
    const { id, pageIndex, onChangeHandler, onBlurHandler, inputtype, value } = this.props
    let dirty = newValue !== value
    onChangeHandler(pageIndex, id, newValue)
    if (inputtype === 'select' || inputtype === 'checkbox' || inputtype === 'date')
    {
      dirty = false
      onBlurHandler(pageIndex, id, newValue)
    }
    this.setState({ value: newValue, dirty })
  }


renderChild = () => {

  const { id,
          componentid,
          visible = true,
          inputtype,
          required,
          translator,
          maxLength,
          suffix,
          label,
          prefix,
          placeHolder,
          readOnly = false,
          options = [],
          error,
          help,
          value } = this.props

  const useLabel = label || id + '/label'
  const hide = visible ? "" : " hidden "
  const helpComponent = (<HelpComponent id={id} content={help} />)
  switch ( inputtype) {

    case inputTypes.date:
      return (
        <FormInput key={id} className={hide} required={required} error={error} label={useLabel} help={helpComponent}>
          <DatePicker selectOnly={false} format={culture.dateFormatting} value={value} placeholder={culture.dateFormatting} onChange={this.onChange} disabled={false} readOnly={false} />
        </FormInput>)

    case inputTypes.select:
      const translatedOptions = (isFunction(options) ? options() : options)
        .map(item => ({ "key": item.key, "value": translator.translation(id + '/items/' + item.key) || item.value }))

      return (
        <FormInput key={id} className={hide} required={required} error={error} label={useLabel} help={helpComponent}>
          <DropdownList onChange={(e) => this.onChange(e.key) } data={translatedOptions} value={value}/>
        </FormInput>)

    case inputTypes.checkbox: {
      const { trueValue = 1, falseValue = "" } = this.props
      return (
        <FormInput key={id} className={hide} required={required} error={error} label={useLabel} help={helpComponent}>
          <ToggleSwitch onChange={(e) => e ? this.onChange(trueValue) : this.onChange(falseValue) } data={options} value={value} checked={trueValue === value} />
        </FormInput>
      ) }

    case inputTypes.line:
      return (<hr/>)

    case inputTypes.header: {
      const { trueValue = 1, falseValue = "" } = this.props
      return <SubAccordion
                key={id}
                className={hide}
                expanded={trueValue === value}
                onChange={(e) => e ? this.onChange(trueValue) : this.onChange(falseValue) }
                label='WHK' />
    }
    case inputTypes.text:
    default:
      return <TextInput
        className={hide}
        required={required}
        label={useLabel}
        data-component={componentid}
        value={value}
        maxLength={maxLength}
        readOnly={readOnly}
        inputtype={inputtype}
        prefix={prefix}
        suffix={suffix}
        error={error}
        placeHolder={placeHolder}
        onBlur={this.onBlur}
        onChange={this.onChange}
        help={helpComponent}
      />
    }
}

  render = () => (<div className="line"> {this.renderChild()} </div>)
}

InputComponent.propTypes = {
  id: PropTypes.string.isRequired,
  inputtype: PropTypes.string.isRequired,
  pageIndex: PropTypes.number.isRequired,
  onBlurHandler: PropTypes.func.isRequired,
  onChangeHandler: PropTypes.func.isRequired,
  required: PropTypes.bool,
  value: PropTypes.any,
  decimals: PropTypes.number,
  validation: PropTypes.func,
  prefix: PropTypes.string,
  suffix: PropTypes.string,
  readonly: PropTypes.bool,
  dirty: PropTypes.bool
}

export default connect()(withTranslator(InputComponent, "InputComponent"))