/*eslint-disable no-unused-vars */
const getCulture = () => {
  return 'nl-NL';

  // const key = "lang="
  // const startIndex = document.location.search.indexOf(key)
  // const [[_, culture = 'nl-NL'] = []] = document.cookie.split(';')
  //     .filter(str => str.includes('lang'))
  //     .map(str => str.trim().split('='))
  // let cultureToUse = culture;
  // if (startIndex !== -1) {
  //   cultureToUse = document.location.search.substring(startIndex + key.length, startIndex + key.length + 2)
  // }
  // return cultureToUse === 'en' ? 'en-GB' : culture;  
};

export {
  getCulture
};