import React, { Component } from 'react'
import { BrowserRouter, Route } from 'react-router-dom'
import App from './App'
import { connect } from 'react-redux'
import { LocalizationProvider } from './localize/LocalizationProvider'

class AppContainer extends Component {
  componentDidMount() {
  }

  render() {
    return (<BrowserRouter basename="" >
      <Route path="/" render={() =>
        <LocalizationProvider value={this.props.culture}>
          <App />
        </LocalizationProvider>
      } />
    </BrowserRouter>)
  }
}

export default connect()(AppContainer)