/*eslint-disable react/no-multi-comp*/

import React from 'react';
import PropTypes from 'prop-types';
import { Alert } from 'adp-react-components';

import { translate } from '../../localize/LocalizationProvider';
const tr = translate("Api");

const empty = [];

const getMessage = (toastr) => {
    const { messageId = "" } = toastr;
    const { message } = messageId;
    const resource = message || toastr.message;
        
    return (resource 
    ? tr(resource) || tr(resource.toUpperCase()) 
    : tr(toastr.type.toUpperCase()))
    || tr('SERVICE_UNAVAILABLE');
}

const renderToastr = (toastr) => {
    return (<div key={toastr.id}
        className="ml-toastr-content">
        <Alert
            autoClose
            alertStyle="default"
            type={toastr.type}
            closeable>{getMessage(toastr)}</Alert>
    </div>);
};

const Toastrs = ({ toastrs = empty }) => {
    return (
        <span className="tp-toastr">
            <div className="bottom-right">
                {toastrs.map(toastr => renderToastr(toastr))}
            </div>
        </span>
    );
};

Toastrs.propTypes = {
    toastrs: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number,
        type: PropTypes.oneOf(["success", "info", "warning", "error"]),
        message: PropTypes.string,
        messageId: PropTypes.string
    }))
};

export default Toastrs;