import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { sumAmounts } from '../tools/CalcuationTools'

import Highcharts from "highcharts"
import drilldow from "highcharts/modules/drilldown"
import { findDOMNode } from 'react-dom'
import { withTranslator, culture } from '../../localize/LocalizationProvider';
import { baseVDLHighchartsOptions } from 'adp-react-components';

drilldow(Highcharts);


const colors = {
  earnings: ["#ffb400", "#db9500", "#756348", "#F5C253", "#D0B120", "#ff8000", "#e67300", "#ff8c1a", "#cc6600", "#ff9933", "#b35900", " #ffa64d", "#994d00", "#ffb366", "#ffbf80", " #804000", "#f9f08f"],
  deductions: ["#fcac6b", "#f47b19", "#d14019", "#872a10", "#B03316", "#E9613D", "#ff6666", "#ff9999", "#ff4d4d", "#cc0000", "#ff8080", "#ff3333", "#ff9999", "#ff1a1a", "#ffb3b3", "#b30000", "#ffcccc"],
  nett: "#3366ff",
  gross: "purple"
};


const topLevel = (name, input, colorCode, drilldown, visible) =>
  input !== undefined && input.amountValue > 0
    ? {
      name: name,
      text: `<b>€ ${culture.formatDecimal(input.amountValue, 2)}</b>`,
      y: input.amountValue,
      drilldown,
      visible
    }
    : null;


const drillDownValue = (id, name, input, colorCode) => {
  return {
    name,
    y: input.amountValue,
    text: `<b>€ ${culture.formatDecimal(input.amountValue, 2)}</b>`
  }
}

class Paylens extends React.Component {
  createOptions(payStatement, translator) {
    const nett = topLevel(translator.translate("nett"), payStatement.netPayAmount, colors.nett, null, true);
    const deduction = topLevel(translator.translate('deduction'), sumAmounts(payStatement.deductions, "amount"), "#d14019", "deductions", true);
    const gross = topLevel("hidden", payStatement.grossPayAmount, "white", "earnings", false);

    const allDeductions = payStatement.deductions.map((item, index) => drillDownValue(item.id || index.toString(), item.codeName, item.amount, colors.deductions[index]));
    const allEarnings = payStatement.earnings.map((item, index) => drillDownValue(item.id || index.toString(), item.earningCodeName, item.amount, colors.earnings[index]));

    const options: Highcharts.Options = Object.assign({}, baseVDLHighchartsOptions, {
      chart: {
        type: 'pie',
        spacingTop: 10,
        height: "200",
        width: "250",
        events: {
          click: (e) => {
            const how = document.elementFromPoint(e.clientX, e.clientY).tagName;
            if (how === "tspan") {
              if (payStatement.grossPayAmount.amountValue > 0) {
                const chart = new Highcharts['Chart'](findDOMNode(this), options);
                chart.series[0].points[2].doDrilldown();
              }
            }
          },
          drillup: (e) => {
            e.target.title.show();
            //createTitleText("Vergoedingen", payStatement.grossPayAmount);
          },
          drilldown: (e) => {
            // if (e.seriesOptions.name === 'earnings') {
            //   e.target.title.textStr = createTitleText("Totaal ", payStatement.grossPayAmount);
            // } else if (e.seriesOptions.name === 'deductions') {
            //   e.target.title.textStr = createTitleText("Totaal ", sumAmounts(payStatement.deductions, "amount"));
            //<span class="paylens-title">Vergoedingen</span><br/><span class="paylens-title">€ 5.000,00</span>
            //}
            e.target.title.hide();
          }
        }
      },
      lang: {
        drillUpText: "◁"
      },
      credits: { enabled: !1 },
      plotOptions: {
        pie: {
          innerSize: "70%",
          allowPointSelect: !0,
          cursor: "pointer",
          dataLabels: {
            style: {
              "textDecoration": "none",
              "fontSize": "14px"
            }
          }
        },
        series: {
          dataLabels: {
            enabled: false,
            //format: '<b>{point.name}</b>: €{point.y:.2f}%'
          },
          tooltip: {
            headerFormat: "",
            pointFormat: '<span style="color:{point.color}">●</span> {point.name}<br/><b>€{point.y:.2f}</b>'
          }
        }
      },
      tooltip: {
        headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
        pointFormat: '<span style="color:{point.color}">{point.name}</span>: <b>{point.y:.2f}%</b> of total<br/>'
      },
      title: {
        id: "earnings",
        text: `<span class="paylens-title">${translator.translate('earnings')}</span><br/><span class="paylens-title">€ ${culture.formatDecimal(payStatement.grossPayAmount.amountValue)}</span>`,
        align: 'center',
        verticalAlign: 'middle',
        drilldown: "earnings",
        style: { color: "blue", fontSize: "14px", fill: "#333333" }
      },
      series: [{
        name: translator.translate("earnings"),
        colorByPoint: true,
        data: [nett, deduction, gross]
      }],
      drilldown: {
        activeAxisLabelStyle: {
          textDecoration: 'none',
          color: 'black'
        },
        activeDataLabelStyle: {
          textDecoration: 'none',
          color: 'black',
        },
        series: [{
          name: "deductions",
          id: "deductions",
          data: allDeductions
        },
        {
          name: "earnings",
          id: "earnings",
          data: allEarnings
        }]
      }
    });
    return options;
  }

  componentDidMount = () => {
    const { payStatement, translator } = this.props;
    const options = this.createOptions(payStatement, translator);
    try {
      const chart = new Highcharts['Chart'](document.getElementById('prepay-chart'), options);
      chart.drillUp();
    } catch (e) {
      // ignore the erros, apperently not visible yet or already at top
    }
  }

  render() {
    return (
      <div className="prepay-Paylens">
        <div id='prepay-chart'></div>
      </div>
    );
  }
}


Paylens.propTypes = {
  payStatement: PropTypes.object.isRequired,
}

export default connect()(withTranslator(Paylens, "PayStatement"));