import React from 'react'
import {Masthead,
  MastheadUtility,
  MastheadLogo } from 'adp-react-components'
import PrepayFooter from '../components/PrepayFooter'

export default ({ children }) => (
  <React.Fragment>
    <div className="vdl-framework-bg">
      <div className="vdl-framework-masthead">
        <div className="vdl-container">
          <div className="vdl-row">
            <div className="vdl-col-xs-12">
              <div className="left-nav-framework__top-bar">
                <Masthead>
                  <MastheadUtility>
                    <MastheadLogo />
                    <h1>Online Loonberekening</h1>
                  </MastheadUtility>
                </Masthead>
              </div>
            </div>
          </div>
          <div className="vdl-row">
            <div className="vdl-col-xs-12">
              {children}
            </div>
          </div>
          <div>
          <PrepayFooter />
          </div>
        </div>
      </div>
    </div>
  </React.Fragment>)