import React from 'react'
import { connect } from 'react-redux'
import InputsView from './InputsView'
import ResultsView from './ResultsView'
import { loadPages, YearChange } from '../../store/reducers/controlsStore'
import { initializeStatement, calculate, isCalculateReady } from '../../store/reducers/calculateStore'
import { withTranslator } from '../../localize/LocalizationProvider'
import { Alert } from 'adp-react-components'
import { createPdf } from '../../store/reducers/calculateStore'


class OverViewPage extends React.Component {
  
  componentDidMount(){
    const { initializeStatement,  loadPages, fiscalYear, YearChange } = this.props
    loadPages()
    initializeStatement()
    YearChange(fiscalYear)
  }

  onCalculateHandler = (update = false) => {
    const { pages = [], calculate } = this.props
    calculate(pages, update)
  }

  onPdfRequestHandler = ( pdfType) => {
    const { payStatements, pages, assignmentCosts, assignment, createPdf } = this.props
    const namePage = pages[pages.length -1]
    assignment.employee.name =
      ([namePage.components[0].value, namePage.components[1].value, namePage.components[2].value])
      .join(" ")
    createPdf({payStatements, assignmentCosts, assignment}, pdfType)
  }


  render(){
    const { error, translator } = this.props

    return (
      <React.Fragment>
        {error
        ? <Alert closeable={false} type="error" title={translator.translate('ERROR_ALERT_TITLE')} >
            { translator.translate('SERVICE_UNAVAILABLE') }
          </Alert>
        : <React.Fragment>
            <InputsView onCalculate={this.onCalculateHandler} />
            <ResultsView onCalculate={this.onCalculateHandler} onPdfRequest={this.onPdfRequestHandler} />
          </React.Fragment> }
      </React.Fragment>)
  }
}

function mapStateToProps(state, ownProps) {
  const { controlsStore, calculateStore } = state
  return {
    ...ownProps,
    ...controlsStore,
    ...calculateStore
  }
}

export default connect(mapStateToProps, { loadPages, YearChange, initializeStatement, calculate, createPdf, isCalculateReady })(withTranslator(OverViewPage, "OverViewPage"))