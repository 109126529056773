import 'babel-polyfill';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import './scss/index.scss';
import{ getCulture } from './localize/culture';
import configureStore from './store/configureStore';
import AppContainer from './AppContainer';
import { createBrowserHistory } from 'history';

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const history = createBrowserHistory({ basename: baseUrl });

// Get the application-wide store instance, pre-populating with state from the server where available.
const initialState = window.initialReduxState;
const store = configureStore(history, initialState);

const rootElement = document.getElementById('root');
const culture = getCulture();


ReactDOM.render(
  <Provider store={store}>
      <AppContainer culture={culture} />
  </Provider>,
  rootElement);