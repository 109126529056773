import React from 'react'
import PropTypes from 'prop-types'
import { culture } from '../../localize/LocalizationProvider';

export const TableRowDiv = ({className='', children, onMouseLeave = () => {}, onMouseEnter = () => {}}) => {    
    return (<div className={`${className} prepay-tablerow`} onMouseLeave={onMouseLeave} onMouseEnter={onMouseEnter}>{children}</div>);
  }
  
  export const TableDivCell = ({ className='', children, onMouseLeave = () => {}, onMouseEnter = () => {}, onClick = () => {}}) => {  
    return (<div className={`${className} prepay-tablecell`} onMouseLeave={onMouseLeave} onMouseEnter={onMouseEnter} onClick={onClick} >{children}</div>);
  }
  
  export const TableDataRow = ({id, label, value, className = '', onMouseLeave = () => {}, onMouseEnter = () => {}, ononClick = () => {}}) => {   
    const onMouseLeaveHandler = (e) => { onMouseLeave(e, id ); }
    const onMouseEnterHander = (e) => { onMouseEnter(e, id); }
    
    return (<TableRowDiv className={`${className} perpay-table-amountrow`} onMouseLeave={onMouseLeaveHandler} onMouseEnter={onMouseEnterHander} >
              <TableDivCell className="prepay-statement-label">{label}</TableDivCell>
              <TableDivCell className="prepay-statement-amount">{value !== undefined ? culture.formatDecimal(value.amountValue, 2) : ""}</TableDivCell>
            </TableRowDiv>);
  }
  export const TableFullWidthRow = ({className='', text = '\u00A0', onMouseLeave, onMouseEnter, onClick}) => {
    return (<TableRowDiv className={className} onMouseLeave={onMouseLeave} onMouseEnter={onMouseEnter} ><TableDivCell onClick={onClick}>{text} </TableDivCell></TableRowDiv>);
  }

  TableDivCell.propTypes = {
    children: PropTypes.any.isRequired,
    className: PropTypes.string,
    onMouseLeave: PropTypes.func,
    onMouseEnter: PropTypes.func,
    onClick: PropTypes.func,
  }

  TableRowDiv.propTypes = {
    children: PropTypes.any.isRequired,
    className: PropTypes.string,
    onMouseLeave: PropTypes.func,
    onMouseEnter: PropTypes.func,
  }

  TableFullWidthRow.propTypes = {
    text: PropTypes.string,
    id: PropTypes.string,    
    className: PropTypes.string,
    onMouseLeave: PropTypes.func,
    onMouseEnter: PropTypes.func,
    onClick: PropTypes.func,
  }

  TableDataRow.propTypes = {
    value: PropTypes.any.isRequired,
    className: PropTypes.string,
    onMouseLeave: PropTypes.func,
    onMouseEnter: PropTypes.func,
    onClick: PropTypes.func,
  }
