import { applyMiddleware, combineReducers, compose, createStore } from 'redux'
import thunk from 'redux-thunk'
import { routerReducer, routerMiddleware } from 'react-router-redux'
import { middleWare as apiMiddleWare } from '../Api'
import { reducer as controlsStore } from './reducers/controlsStore'
import { reducer as calculateStore } from './reducers/calculateStore'
import toastrs from './reducers/toasters'

export default function configureStore(history, initialState) {
  const reducers = {
    controlsStore,
    calculateStore,
    toastrs
  }

  const middleware = [
    apiMiddleWare,
    thunk,
    routerMiddleware(history)
  ]

  // In development, use the browser's Redux dev tools extension if installed
  const enhancers = []
  const isDevelopment = process.env.NODE_ENV === 'development'
  if (isDevelopment && typeof window !== 'undefined' && window.devToolsExtension) {
    enhancers.push(window.devToolsExtension())
  }

  const rootReducer = combineReducers({
    ...reducers,
    routing: routerReducer
  })

  return createStore(
    rootReducer,
    initialState,
    compose(applyMiddleware(...middleware), ...enhancers)
  )
}