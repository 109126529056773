import React from 'react';
import PropTypes from 'prop-types';
import { withTranslator } from '../localize/LocalizationProvider';

const translate = (translator, error) => {
    if (error === null || error === "") return "";
    if (error.template !== undefined) {
        const translation = translator.translation((`${error.id}/${error.template}`).toUpperCase())
                         || translator.translation((`GENERIC/${error.template}`).toUpperCase())
                         || translator.translate(error.template.toUpperCase());
        const elements = translation.split("{{");
        let result= "";
        elements.forEach(element => {
          let end = element.indexOf("}}");
          if (end !== -1) {
            result += error[element.substring(0, end).toLowerCase()] + element.substring(end + 2);
          } else {
              result += element;
          }
        });
        return result;
    }
    return translator.translate(error.toUpperCase());
}

const ValidationError = ({ error = "", translator }) => {
    const className = error === "" ? "" : "invalid-label";
    return (<div className={className}>{translate(translator, error)}</div>)
}

ValidationError.propTypes = {
    error: PropTypes.any
};

export default withTranslator(ValidationError, "Errors");