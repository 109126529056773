import React from 'react'
import PropTypes from 'prop-types'
import InputComponent from './InputComponent'
import { withTranslator } from '../../localize/LocalizationProvider'
import { Accordion } from 'adp-react-components'

const PageComponent = ({ translator, id, pageIndex, title, components, inError = false, actifIndex, onBlurHandler, onChangeHandler, onOpenCollapse }) => {
  const onSelect = ()=> onOpenCollapse(pageIndex, actifIndex !== pageIndex)
  const pageName =  title !== undefined ? title : translator.translate(id)
  const header = () => (<span className={`page-header--${(inError ? "inError" : "" )}`}>{pageName}</span>)

  return components.length < 1 ? null : (
    <div className="page">
      <Accordion autoCollapse={false} type="pills" className="chevron-right" onSelect={onSelect} >
        <Accordion.Panel expanded={pageIndex === actifIndex} header={header()} eventKey={pageIndex}>
        {components.map(control =>
            React.createElement(InputComponent, { pageIndex, onBlurHandler, onChangeHandler, ...control }))}
        </Accordion.Panel>
      </Accordion>

    </div>)
}
PageComponent.propTypes = {
  id: PropTypes.string.isRequired,
  pageIndex: PropTypes.number.isRequired,
  components: PropTypes.array.isRequired,
  actifIndex: PropTypes.number.isRequired,
  onBlurHandler: PropTypes.func.isRequired,
  onChangeHandler: PropTypes.func.isRequired,
  onOpenCollapse: PropTypes.func.isRequired,
  inError: PropTypes.bool
}
export default withTranslator(PageComponent, "PageComponent")