import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { BusyIndicator, Popover, OverlayTrigger } from 'adp-react-components'
import Paylens from '../components/Paylens'

import PayStatementView from '../components/PayStatementView'
import AssignmentCostsView from '../components/AssignmentCostsView'
import { Alert, Button, DropdownButton } from 'adp-react-components'

import { withTranslator, translate } from '../../localize/LocalizationProvider'
import AngleDoubleUp from 'adp-react-icons/lib/fa/angle-double-up'

const tr = translate("Api")


class ResultsView extends React.Component {
  constructor(props) {
    super(props)
    this.state = { dime: false, isVisible: false }
  }

  componentDidMount = () => {
    window.addEventListener('scroll', this.handleScroll)
  }

  componentWillUnmount = () => {
    window.removeEventListener('scroll', this.handleScroll)
  }

  onCalculateHander = () => {
    const { onCalculate } = this.props
    const { dime } = this.props
    onCalculate(true)
    this.setState({ dime: !dime })
  }

  onClick_PdfDownloadButton = (pdfType) => {
    const { payStatements, assignmentCosts, assignment, createPdf } = this.props
    createPdf({ payStatements, assignmentCosts, assignment }, pdfType)
  }

  handleSelect = (e) => {
    const { onPdfRequest, translator } = this.props
    if (e === translator.translate('downloadButton')) {
      onPdfRequest('payslip')
    } else {
      onPdfRequest('assignmentcost')
    }
  }

  scrollIntoView = () => {
    this.onCalculateHander()
    this.refs.paylens.scrollIntoView()
  }

  scrollToTop = () => {
    window.scrollTo(0, 0)
  }

  handleScroll = () => {
    const el = this.refs.buttons
    if (el !== undefined && window.scrollY > (el.getBoundingClientRect().bottom)) {
      this.setState({ isVisible: true })
    } else this.setState({ isVisible: false })
  }


  render() {
    const isNullOrEmpty = (collection) => collection === undefined || collection === null || collection.length < 1
    const { payStatements, assignmentCosts, busy, error, validationMessage, translator, responseId } = this.props
    const { isVisible } = this.state

    const pdfAvailable = !isNullOrEmpty(payStatements) && !payStatements[0].demo && (payStatements[0].earnings.length > 0 || payStatements[0].deductions.length > 0)
    const downloadOptions = [translator.translate('downloadButton'), translator.translate('assignmentCost')]

    const popover = (text) => (<Popover id="popover-2">{text}</Popover>)
    
    const paylensKey = responseId || 0;    

    return (<div className="prepay-resultpane">
      {busy ? <BusyIndicator overlay={true} size="lg" fullScreen={false} /> : undefined}
      {validationMessage
        ? <Alert closeable={false} type="error" title={tr('VALIDATION_ALERT_TITLE')} >
          {translator.translate(validationMessage)}
        </Alert> : undefined}
      {error
        ? <Alert closeable={false} type="error" title={tr('ERROR_ALERT_TITLE')} >
          {tr(error) || tr('SERVICE_UNAVAILABLE')}
        </Alert> : undefined}
      {payStatements && payStatements.length > 0
        ? (<React.Fragment>
          <div ref="paylens" className="prepay-paylens-container">
            <React.Fragment>
              <Paylens key={paylensKey} payStatement={payStatements[0]} busy={busy} />
              <div ref="buttons" className="prepay-button-group">
                <Button buttonStyle="secondary" onClick={this.onCalculateHander}>{translator.translate('calculatebutton')}</Button>
                <DropdownButton buttonStyle="secondary" disabled={!pdfAvailable} data={downloadOptions} onSelect={this.handleSelect}><span className="vdl-dropdown-button__text">Download </span></DropdownButton>
              </div>
            </React.Fragment>
          </div>
          <div className="tableContainer">
            <PayStatementView payStatement={payStatements[0]} />
            <AssignmentCostsView assignmentCosts={assignmentCosts[0]} payStatement={payStatements[0]} />
          </div>
        </React.Fragment>)
        : undefined}
      {payStatements && payStatements.length > 0 && !isVisible
        ? (<div className="downbutton" onClick={this.scrollIntoView}>
          <OverlayTrigger
            trigger={['hover']}
            placement="left"
            overlay={popover(translator.translate('calculate_showResults'))}>
            <Button buttonStyle="secondary">{translator.translate('calculatebutton')} </Button>
            {/* <AngleDoubleDown style={{"font-size": "20px"}} /> */}

          </OverlayTrigger>
        </div>) : undefined}
      {isVisible
        ? (<div className="upbutton" onClick={this.scrollToTop}>
          <OverlayTrigger
            trigger={['hover']}
            placement="left"
            overlay={popover(translator.translate('toTop'))}>
            <AngleDoubleUp />
          </OverlayTrigger>
        </div>) : undefined}
    </div>)
  }
}


ResultsView.propTypes = {
  onCalculate: PropTypes.func.isRequired,
  payStatements: PropTypes.array.isRequired,
  assignmentCosts: PropTypes.array.isRequired,
  busy: PropTypes.bool.isRequired
}


function mapStateToProps(state, ownProps) {  
  return {
    ...ownProps,
    ...state.calculateStore
  }
}


export default connect(mapStateToProps)(withTranslator(ResultsView, "ResultsView"))
