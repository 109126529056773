import React from 'react';
//import { formatMessage } from '../localize/LocalizationProvider';
import { Footer } from 'adp-react-components';
import ExternalLinkIcon from 'adp-react-icons/lib/fa/external-link';


const PrepayFooter = () => {
    return (<Footer>
              <ul>
                <li>Prepay geeft slechts een indicatie en aan deze resultaten kunt u derhalve geen rechten ontlenen.</li>
              </ul>
              <ul>
                <li>
                  <span><a href="https://www.adp.nl" target="_blank" rel="noopener noreferrer">ADP Nederland BV</a><ExternalLinkIcon /> is ingeschreven in het Handelsregister onder dossiernummer 24098777</span>
                </li>
                </ul>
                <ul>
                <li>
                    <span>Copyright &copy;2016 - { new Date().getFullYear() } ADP, LLC ALL RIGHTS RESERVED.</span>&nbsp;<br/>
                  <span><a href="https://www.adp.nl/over-adp/privacy/" target="_blank" rel="noopener noreferrer">Privacy</a><ExternalLinkIcon /></span>&nbsp;
                  <span><a href="https://www.adp.nl/over-adp/disclaimer/" target="_blank" rel="noopener noreferrer">Disclaimer</a><ExternalLinkIcon /></span>

                </li>
                </ul>
                <ul>
                <li>

                </li>
              </ul>
            </Footer>)
}

export default PrepayFooter