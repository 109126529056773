const output =
{
  "errors": [{ "msg": "need an error for start" }],
  "assignment": {
    "employee": {
      "name": "",
      "birthday": "1981-12-31",
      "address": {
      }
    },
    "organisation": {
      "name": "PREPAY ALGEMEEN",
      "address": {
        "lineOne": "Lylantse Baan 1",
        "cityName": "Capelle a/d IJssel",
        "postalCode": "2908LG "
      },
      "additionalProperties": {
        "consecutiveNumberIncomeRelationship": "0001"
      }
    },
    "startDate": "0001-01-01",
    "additionalProperties": {
      "partTimePercentage": 0,
      "percentageSpecialAdditions": 0,
      "yearlyWage": 0.0,
      "timeboxDaysSocialSecurity": 0,
      "codeWageTaxTable": "",
      "taxdiscount": "True",
      "labourRebate": 0,
      "days": 0,
      "zw-ww-wia": "True",
      "wao": "False",
      "wga": "True",
      "incometype": 0,
      "employmenttype": "",
      "terminationcode": null,
      "incomerationumber": "",
      "zvw": ""
    }
  },
  "payStatements": [
    {
      "demo": true,
      "payPeriod": {
        "periodNumber": 1,
        "startDate": "2019-01-01"
      },
      "netPayAmount": {
        "amountValue": 0,
        "currencyCode": "EUR"
      },
      "grossPayAmount": {
        "amountValue": 0,
        "currencyCode": "EUR"
      },
      "grossPayYTDAmount": {
        "amountValue": 0,
        "currencyCode": "EUR"
      },
      "earnings": [
        {
          "id": "020",
          "earningCodeName": "Salaris",
          "earningAmount": {
            "amountValue": 0,
            "currencyCode": "EUR"
          },
          "earningYTDAmount": {
            "amountValue": 0,
            "currencyCode": "EUR"
          },
          "labelName": "Salaris",
          "amount": {
            "amountValue": 0,
            "currencyCode": "EUR"
          },
          "ytdAmount": {
            "amountValue": 0,
            "currencyCode": "EUR"
          },
          "tableAmount": {
            "amountValue": 0,
            "currencyCode": "EUR"
          },
          "specialTariff": {
            "amountValue": 0,
            "currencyCode": "EUR"
          },
          "retroActive": {
            "amountValue": 0,
            "currencyCode": "EUR"
          }
        }
      ],
      "deductions": [

      ],
      "directDeposits": [
      ],
      "otherPay": [
      ],
      "employer": {
        "employerName": "26310 PREPAY ALGEMEEN",
        "employerLocation": {
          "address": {
            "lineOne": "Lylantse Baan 1",
            "cityName": "Capelle a/d IJssel",
            "postalCode": "2908LG "
          }
        }
      },
      "payrollFileNumber": "0009999",
      "payrollGroupNumber": 26310,
      "hourAccountability": {
        "days": [
          {
            "accountabilityId": 193,
            "description": "Gewerkte dagen",
            "value": 23.00
          },
          {
            "accountabilityId": "KD1",
            "description": "Tot dagen  193",
            "value": 23.00
          }
        ]
      }
    }
  ],
  "assignmentCosts": [
    {
      "payPeriod": {
        "periodNumber": 1,
        "startDate": "2019-01-01"
      },
      "employeeCost": {
        "items": [

        ]
      },
      "employerCost": {
        "items": [
        ]
      }
    }
  ]
};

export default output;