import React from 'react';
import PropTypes from 'prop-types';
import { withTranslator } from '../localize/LocalizationProvider';
import { connect } from 'react-redux';
import ValidationError from './ValidationError';


const translateLabel = (translator, resource, year) => {
    const label = translator.translation(resource)
    return label ? label[year] || label['default'] || label['label'] || label : resource

}


class Label extends React.Component {
    render = () => {
        const { fiscalYear, translator, labelId,  error, required = false } = this.props

        return (
            <React.Fragment>
                <ValidationError error={error} />
                <label>
                    <span>{translateLabel(translator, labelId, fiscalYear)}</span>
                    {required ? <span className="required"> *</span> : null}
                </label>
            </React.Fragment>)
    }
}

function mapStateToProps(state, ownProps) {
    const { controlsStore: { fiscalYear } } = state

    return {
      ...ownProps,
      fiscalYear
    }
  }

Label.propTypes = {
    labelId: PropTypes.string.isRequired,
    required: PropTypes.bool,
    error: PropTypes.any
};

export default connect(mapStateToProps)(withTranslator(Label, "InputComponent"));