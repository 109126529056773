// !!! JS Dates uses a zero month index. 0 is jan, 1 is feb.
// Increase this counter in case you forgot, currentcount: 42 :)

const valuesToDate = (values) => {
  let fullYear = new Date().getFullYear().toString()
  const [year = fullYear, month = '1', day = '1'] = values

  return new Date(`${year}-${month}-${day}`)
}

export const dateParsers = {
  'nl': {
    datesToString: (formatMessage) => ({
      'e': (value) => `${value.getDay()}`,
      'eee': (value) => `${formatMessage(`DateFormat/Days/Part/${value.getDay()}`)}`,
      'd': (value) => `${value.getDate()}`,
      'dd-MM-yyyy': (value) => {
        const dayNr = value.getDate().toString().padStart(2, '0')
        const monthNr = (value.getMonth() + 1).toString().padStart(2, '0')
        const yearNr = value.getFullYear()
        return `${dayNr}-${monthNr}-${yearNr}`
      },
      'MMM': (value) => formatMessage(`DateFormat/Months/Part/${value.getMonth()}`),
      'MMMM yyyy': (value) => {
        const monthName = formatMessage(`DateFormat/Months/Full/${value.getMonth()}`)
        const yearNr = value.getFullYear()
        return `${monthName} ${yearNr}`
      },
      'MM-dd-yyyy': (value) => {
        const dayNr = value.getDate().toString().padStart(2, '0')
        const monthNr = (value.getMonth() + 1).toString().padStart(2, '0')
        const yearNr = value.getFullYear()
        return `${monthNr}-${dayNr}-${yearNr}`
      },
      'MM-yyyy': (value) => {
        const monthNr = (value.getMonth() + 1).toString().padStart(2, '0')
        const yearNr = value.getFullYear()
        return `${monthNr}-${yearNr}`
      },
      'yyyy': (value) => `${value.getFullYear()}`,
      'full': (value) => {
        const dayName = formatMessage(`DateFormat/Days/Full/${value.getDay()}`)
        const dayNr = value.getDate()
        const monthName = formatMessage(`DateFormat/Months/Full/${value.getMonth()}`)
        const yearNr = value.getFullYear()
        return `${dayName} ${dayNr} ${monthName} ${yearNr}`
      },
      'short': (value) => {
        const dayNr = value.getDate().toString().padStart(2, '0')
        const monthNr = (value.getMonth() + 1).toString().padStart(2, '0')
        const yearNr = value.getFullYear()
        return `${dayNr}-${monthNr}-${yearNr}`
      }
    }),
    stringToDate: () => ({
      'MM-yyyy': (value) => {
        let values = value.split('-')
        if (values.length === 0 || values.length > 2) { return }
        return valuesToDate(values.reverse())
      },
      'MM-dd-yyyy': (value) => {
        let values = value.split('-')
        if (values.length === 0 || values.length > 3) { return }
        let [month, day, year] = values
        return valuesToDate([year, month, day])
      },
      'dd-MM-yyyy': (value) => {
        let values = value.split('-')
        if (values.length === 0 || values.length > 3) { return }
        let [day, month, year] = values
        return valuesToDate([year, month, day])
      }
    })
  }
}

export const formatter = (formatters) => (value, option) => {
  let f = formatters[option]
  if (!f) {
    console.log('dateformat option not supported: ' + option)
    return
  }
  return f(value)
}

// wesley stuff still in use for file pickers
function stringToDate(d, format) {
  let dateSplit
  if (d.includes('/')) {
    dateSplit = d.split('/')
  } else if (d.includes('-')) {
    dateSplit = d.split('-')
  }

  try {
    switch (format) {
      case 'YYYY':
        return new Date(dateSplit, 0)
      case 'MM/YYYY':
        return new Date(dateSplit[1], dateSplit[0] - 1)
      case 'MM/DD/YYYY':
        return new Date(d)
      default:
        console.warn('Non specified format used: ' + format)
        return Date.parse(d)
    }
  } catch (error) {
    return null
  }
}

export const parseDate = (value, format) => {
  let parsedDate = stringToDate(value, format)
  return !isNaN(parsedDate) ? parsedDate : null
}

export const getDateAndTime = (date) => {
  let parsedDate = new Date(date)
  let fullDate = `${getDateTypeFull(parsedDate, 'getMonth')}/${getDateTypeFull(parsedDate, 'getDate')}/${parsedDate.getFullYear()}`
  let time = `${getDateTypeFull(parsedDate, 'getHours')}:${getDateTypeFull(parsedDate, 'getMinutes')}`
  return `${fullDate} ${time}`
}

function getDateTypeFull(value, prop) {
  return ('0' + (value[prop]() + 1)).slice(-2)
}
// end wesley stuff
