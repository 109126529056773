import React, { Component } from 'react';
import { globalizeLocalizer } from 'adp-react-components';
import { getCulture } from './culture';
import { formatter, dateParsers } from './DateFormatter'
//import { formatter, parseDate } from './DateFormatter'
const { Provider, Consumer } = React.createContext('nl-NL');

const translations = (local) => {
  const promisesNl = () => [
    import(
      /* webpackChunkName: 'resources-nl' */
      /* webpackMode: "lazy" */
      `./nl-NL.json`),
      import(
        /* webpackChunkName: 'resources-nl' */
        /* webpackMode: "lazy" */
        `adp-react-components/locale/nl.json`)
  ];

   const promisesEnGb = () => [
     import(
      /* webpackChunkName: 'resources-en-gb' */
      /* webpackMode: "lazy" */
       `./en-GB.json`),
       import(
        /* webpackChunkName: 'resources-en-gb' */
        /* webpackMode: "lazy" */
         `adp-react-components/locale/en-GB.json`)
   ];
  return Promise.all(local === 'nl-NL' ? promisesNl() : promisesEnGb());
};

const globalize = {
  _locale: 'nl-NL',
  _messages: {},
  locale: function (locale) {
    if (locale && locale !== '') {
      this._locale = locale;
    }
    return this._locale;
  },
  loadMessages: function (messages) {
    const thisMessages = this._messages;
    Object.keys(messages).map(key => thisMessages[key] = { ...thisMessages[key], ...messages[key] });
  },
  formatDate: function (value, { raw, date }) { // Gets called f.e. when a user uses the DatePicker
    if (!value) {
      return undefined
    }
    // For now only dutch formatters created
    return formatter(dateParsers['nl'].datesToString(this.formatMessage.bind(this)))(value, raw || date)
  },
  parseDate: function (value, { raw, date }) { // Gets called f.e. when a user types the date in a DatePicker
    if (!value) {
      return undefined
    }
    if (typeof value !== 'string') {
      return value
    }
    // For now only dutch formatters created
    return formatter(dateParsers['nl'].stringToDate())(value, raw || date)
  },
  formatNumber: function () {
    return arguments[0].toString();
  },
  formatMessage: function (path = "") {
    const translations = this._messages[this._locale];
    if (path === "") { return undefined; }
    if (!translations) { return path; }

    const parts = path.split('/');
    return parts.reduce((pathObject, current) => {
      const m = pathObject ? pathObject[current] : undefined;
      return m; }, translations);
  }
};

export class LocalizationProvider extends Component {
  state = { loading: true };
  load() {
    const self = this;
    globalize.locale(this.props.value);
    translations(this.props.value).then(([translations]) => {
      globalize.loadMessages(translations);
      self.setState({
        loading: false
      });
    });
  }

  componentDidMount() {
    this.load();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.value !== this.props.value) {
      this.load();
    }
  }

  render() {
    const { loading } = this.state;
    return (
      loading
        ? null
        : (<Provider value={this.props.value}>
          {this.props.children}
        </Provider>)
    );
  }
}

let withTranslator;
if (process.env.NODE_ENV === 'test') {
  withTranslator = function consume(Component) {
    return function render(props) {
      return <Component {...props} translator={{
        translate: key => key
      }} />;
    }
  };
}
else {
  withTranslator = function consume(Component, pathPrefix = "") {
    return function render(props) {
      return (<Consumer>
        {locale => <Component
          {...props}
          translator={{
            translate: (key) => globalize.formatMessage(`${pathPrefix}/${key}`) || `!_${pathPrefix}/${key}`,
            translation: (key) => globalize.formatMessage(`${pathPrefix}/${key}`)
          }} />}
      </Consumer>);
    }
  };
}

const tryF = (f) => {
  try {
      return f();
  } catch (e) {
      return undefined;
  }
};

const formatDecimal = (amount, decimalCount = 2, decimal = ".", thousands = ",")  =>
{
  try {
    decimalCount = Math.abs(decimalCount);
    decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

    const negativeSign = amount < 0 ? "-" : "";

    let i = parseInt(amount = Math.abs(Number(amount) || 0, 10).toFixed(decimalCount)).toString();
    let j = (i.length > 3) ? i.length % 3 : 0;

    return negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) + (decimalCount ? decimal + Math.abs(amount - i).toFixed(decimalCount).slice(2) : "");
  } catch (e) {
    //  return empty value when invalid
    return '';
  }
};
const isNullOrEmptyOrWhitspace = (value) => value === null || (value + " ").trim().length === 0;
const currentCulture = getCulture().substr(0, 2).toLowerCase();
const formatMessage = (namespace) => (prop) => tryF(() =>  globalize.formatMessage(`${namespace}/${prop}`)) || `!_${namespace}/${prop}`;
const translate = (namespace)  => (prop) => tryF(() =>  globalize.formatMessage(`${namespace}/${prop}`)) || undefined;
const dateFormat = currentCulture === 'nl' ? 'dd-MM-yyyy' : 'MM-dd-yyyy';
const culture = {
  current: currentCulture,
  formatDecimal: (amount, decimals) =>
    isNullOrEmptyOrWhitspace(amount) || isNaN(amount) ? "" : currentCulture === 'nl' ? formatDecimal(amount, decimals, ',', '.') : formatDecimal(amount, decimals),
  parseDecimal: (value) => {
    const n = currentCulture === 'nl'
      ? value.toString().replace(/[,.]/g, function (x) { return x === "," ? "." : ""; })
      : value.toString().replace(',', '');
    return !isNaN(n) ? parseFloat(n) : NaN;
  },
  parseDate: function (value, { raw, date }) { // Gets called f.e. when a user types the date in a DatePicker
      if (!value) {
        return undefined
      }
      if (typeof value !== 'string') {
        return value
      }
      // For now only dutch formatters created

      return formatter(dateParsers['nl'].stringToDate())(value, raw || date)
  },
  toLocaleDateString: (date) =>
    date.toLocaleDateString(currentCulture),
  toLocaleString: (value) =>
    value.toLocaleString(currentCulture),
  dateFormatting: dateFormat
};

export {
  withTranslator,
  formatMessage,
  translate,
  culture
};
globalizeLocalizer(globalize);